module.exports = [
  {
    id: 1,
    title: 'Todos os planos das 100+',
    text: `Todos os planos com as <b>100 aulas que mais
    caíram no ENEM</b>, Fuvest, UNESP, Unicamp,
    UFU, UEL, UFPR, Famerp, Fameca, Famema e
    Unaerp.`,
    icon: true,
    filter: 1,
  },
  {
    id: 2,
    title: 'Aulas de 3 minutos',
    text: `Nosso método exclusivo permite a
     <b>compactação de todo o conteúdo em
     apenas 3 minutos</b>, para que você possa
     revisar muito mais e menos tempo com
     nossas <b>mais de 1.000 aulas de revisão</b>!`,
    icon: true,
    filter: 1,
  },
  {
    id: 3,
    title: 'Listas de Exercícios',
    text: `Cada aula possui uma lista de exercícios com
    gabarito e vídeo com a resolução comentada dos principais exercícios,
    para que você possa praticar o que acabou de revisar.`,
    icon: true,
    filter: 1,
  },
  {
    id: 4,
    title: 'Panfletos de Resumo',
    text: `Cada aula possui disponível para download um PDF
    com o material (slides) utilizados na aula com espaços para que você faça suas próprias anotações.`,
    icon: false,
    filter: 1,
  },
]
