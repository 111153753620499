import styled, { css } from 'styled-components'
import { InfoCircleFill } from '@styled-icons/bootstrap'

const Container = styled.div`
  ${({ theme }) => css`
    margin: ${theme.spacings.xxsmall} 0;
    padding: ${theme.spacings.xsmall};
    width: ${theme.width.large};
    color: ${theme.colors.text};
    font-size: ${theme.font.xsmall};
    background-color: ${theme.colors.bgGreen};
    border: 1px solid ${theme.colors.borderGreen};
    border-radius: 2px;
    display: flex;

    &.payment-finish-wrapper {
      margin-top: ${theme.spacings.xsmall};
    }
  `}

  svg {
    margin-right: 10px;
  }
`

const Wrapper = styled.div`
  display: block;
`

const TextWrapper = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.xxsmall};
    display: flex;
    justify-content: center;
    align-items: center;
  `}
`

const Title = styled.p`
  font-weight bold;
`

const Text = styled.p``

const TastingMessageInfoCircleFill = styled(InfoCircleFill)`
  ${({ theme }) => css`
    width: ${theme.font.sizes.small};
    color: ${theme.colors.secondary};
  `}
`


export {
  Container,
  Wrapper,
  TextWrapper,
  Title,
  Text,
  TastingMessageInfoCircleFill
}
