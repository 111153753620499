import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import Providers from "./providers";
import ScrollTop from "./utils/scroll-top";
import Routes from "./routes";
import GlobalStyle from "./styles/global";

function App() {
  return (
    <>
      <BrowserRouter>
        <Providers>
          <ScrollTop />
          <Routes />
          <ToastContainer />
          <GlobalStyle />
        </Providers>
      </BrowserRouter>
    </>
  );
}

export default App;
