import React from "react";

import * as S from "../styles";

import Heading from "../../../shared/Heading";

function StudentInfo(props) {
  const { info } = props;

  function maskMobile(number) {
    if (number.length === 11) {
      const part1 = number.slice(0, 2);
      const part2 = number.slice(2, 7);
      const part3 = number.slice(7, 11);

      return `(${part1}) ${part2}-${part3}`;
    } else {
      const part1 = number.slice(0, 2);
      const part2 = number.slice(2, 6);
      const part3 = number.slice(6, 10);

      return `(${part1}) ${part2}-${part3}`;
    }
  }

  return (
    <>
      <Heading lineBottom>Dados do Assinante</Heading>

      <S.DetailsCard>
        <S.DetailsInfo>
          <S.DetailsInfoStrong>Nome:</S.DetailsInfoStrong> {info.name}
        </S.DetailsInfo>

        {!!info.cpf && (
          <S.DetailsInfo>
            <S.DetailsInfoStrong>CPF:</S.DetailsInfoStrong>{" "}
            {info.cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")}
          </S.DetailsInfo>
        )}

        {!!info.email && (
          <S.DetailsInfo>
            <S.DetailsInfoStrong>E-mail:</S.DetailsInfoStrong> {info.email}
          </S.DetailsInfo>
        )}

        {!!info.mobile && (
          <S.DetailsInfo>
            <S.DetailsInfoStrong>Telefone/Celular:</S.DetailsInfoStrong>{" "}
            {maskMobile(info.mobile)}
          </S.DetailsInfo>
        )}
      </S.DetailsCard>
    </>
  );
}

export default StudentInfo;
