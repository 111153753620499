import styled, { css } from "styled-components";

const wrapperModifiers = {
  lineLeft: (theme) => css`
    border-left: 7px solid ${theme.colors.primary};
  `,

  lineBottom: (theme) => css`
    padding-left: 0;
    position: relative;
    margin-bottom: ${theme.spacings.medium};

    &::after {
      content: " ";
      border-bottom: 7px solid ${theme.colors.primary};
      width: 5rem;
      position: absolute;
      left: 0;
      bottom: -1rem;
    }
  `,
};

export const Wrapper = styled.h2`
  ${({ theme, lineBottom }) => css`
    padding-left: 1rem;
    font-size: ${theme.font.sizes.large};

    ${lineBottom && wrapperModifiers.lineBottom(theme)};
    ${!lineBottom && wrapperModifiers.lineLeft(theme)};
  `}
`;
