import React from 'react'

import * as S from './styles'

function YourInformationAccess(props) {
  const { title, description, image } = props

  return (
    <S.Container>
      <S.Text
        fontFamily
        fontLarge
        fontBold
        margin
      >{title}</S.Text>

      {description?.map((item) => (
        <S.Description>
          <S.Text fontFamily fontSmall>{item.lineOne}</S.Text>
          <S.Text fontFamily fontSmall>{item.lineTwo}</S.Text>
        </S.Description>
      ))}

      <S.Image src={image} alt={title} />
    </S.Container>
  )
}

export default YourInformationAccess
