/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from 'react'
import { Row, Col } from 'react-flexbox-grid'

import useData from '../../../hooks/useData'

import Input from '../../../shared/Input'
import YourInformationAccess from './yourInformationAccess'

import imageLogo from '../../../assets/img/coruja-sabia-logo.svg'
import imageReviewClasses from './yourInformationAccess/images/aulas-de-revisao.jpg'
import imageEssentialClasses from './yourInformationAccess/images/aulas-essenciais.jpg'

import * as S from './styles'

function VivoValorize(props) {
  const { planId, campaign } = props

  const [plan] = useState(planId)
  const [discountCouponCode, setDiscountCouponCode] = useState('')
  const [couponCheck, setCouponCheck] = useState(false)
  const [alertError, setAlertError] = useState(false)

  const {
    loading,
    getCoupon,
    coupon,
    couponInvalid,
    setCouponInvalid,
  } = useData()

  const checkButtons = useCallback((couponCode, type, emptyInvalid) => {
    setAlertError(false)

    if ((discountCouponCode && [undefined].includes(couponCode, type)) ||
      (discountCouponCode === '' && type === 'empty'))
      setAlertError(true)

    if (couponInvalid)
      setAlertError(true)

    if (emptyInvalid)
      setAlertError(false)
  }, [coupon, couponInvalid, discountCouponCode])

  useEffect(() => {
    setCouponCheck(false)

    if (discountCouponCode)
      checkButtons(discountCouponCode, '')

    if (coupon) {
      setCouponInvalid(false)
      setAlertError(false)
      setCouponCheck(true)
    }
  }, [coupon])

  useEffect(() => {
    if (couponInvalid)
      checkButtons(discountCouponCode, 'invalid')

    if (!couponInvalid)
      checkButtons('', '', 'emptyInvalid')
  }, [couponInvalid])

  useEffect(() => {
    if (!loading && couponCheck)
      window.location.href = `/finalizar-assinatura?cart=${plan}&promo=${discountCouponCode}&campaignName=${campaign}`
  }, [loading, couponCheck])

  async function handleCouponCheck(value) {
    setDiscountCouponCode(value)

    if (value) {
      await getCoupon(plan, discountCouponCode, 'toastCouponActive')
    } else {
      checkButtons(value, 'empty')
      setCouponInvalid(false)
    }
  }

  function handleClickButtonScroll() {
    window.open('https://www.corujasabia.com.br/#hs_cos_wrapper_widget_1633612721189', '_blank')
  }

  return (
    <S.Container>
      <Row start="xs" center="xs" className="row-background">
        <Col xs={12} sm={12} md={12} lg={6}>
          <S.Wrapper>
            <S.Image src={imageLogo} title="Logo Coruja Sábia" />

            <S.TextMain>Revise as aulas que mais <br />caíram no ENEM e Fuvest</S.TextMain>

            <S.Text fontFamily>Comece inserindo o código do seu cupom:</S.Text>

            <S.InputWrapper className={alertError ? 'alert-error' : ''}>
              <Input
                name="discountCouponCode"
                placeholder="Informe o código do seu cupom"
                value={discountCouponCode}
                onChange={(e) => setDiscountCouponCode(e.target.value)}
                layout
              />
            </S.InputWrapper>

            <S.Button onClick={() => handleCouponCheck(discountCouponCode)}>
              Começar agora
            </S.Button>
          </S.Wrapper>
        </Col>
      </Row>

      <S.Wrapper>
        <Row start="xs" center="xs" className="information-access">
          <S.Text
            fontBold
            fontXlarge
            colorBlack
            className="text"
          >
            Ao quê você vai ter acesso?
          </S.Text>

          <Row className="information-access-wrapper">
            <Col xs={12} sm={12} md={12} lg={6}>
              <YourInformationAccess
                title="Aulas de Revisão"
                description={[
                  {
                    'lineOne': 'As 50 aulas que mais caíram no ENEM +',
                    'lineTwo': 'as 50 aulas que mais caíram na Fuvest.'
                  }
                ]}
                image={imageReviewClasses}
              />
            </Col>

            <Col xs={12} sm={12} md={12} lg={6}>
              <YourInformationAccess
                title="Aulas Essenciais"
                description={[
                  {
                    'lineOne': '40 aulas completas distribuídas entre todas as disciplinas',
                    'lineTwo': 'com os assuntos essenciais.'
                  }
                ]}
                image={imageEssentialClasses}
              />
            </Col>

          </Row>
        </Row>

        <Row start="xs" center="xs" className="want-more">
          <Col xs={12} sm={12} md={12} lg={6}>
            <S.Text
              fontBold
              fontLarge
              colorBlack
              className="text"
            >
              Quer mais?
            </S.Text>

            <S.Text
              fontFamily
              colorLight
              fontSmall
            >Você também receberá uma super vantagem:</S.Text>

            <S.Text
              colorBlack
              fontBold
              fontLarge
              className="text-discount"
            >50% de desconto em qualquer plano!</S.Text>

            <S.Button onClick={handleClickButtonScroll}>
              Começar agora
            </S.Button>
          </Col>
        </Row>
      </S.Wrapper>
    </S.Container>
  )
}

export default VivoValorize
