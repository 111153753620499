import React from "react";

import * as S from "../styles";

import Heading from "../../../shared/Heading";

import formatCurrency from "../../../utils/format-currency";

function PlanInfo(props) {
  const { subscription } = props;

  return (
    <>
      <Heading lineBottom>Plano de Assinatura</Heading>

      <S.DetailsCard>
        <S.DetailsInfo>{subscription.name}</S.DetailsInfo>
        <S.DetailsInfo>{subscription.description}</S.DetailsInfo>

        {!!subscription.amount > 0 && (
          <S.DetailsInfo>
            <S.DetailsInfoStrong>Total da Assinatura:</S.DetailsInfoStrong> R${" "}
            {formatCurrency(subscription.amount)}
          </S.DetailsInfo>
        )}
      </S.DetailsCard>
    </>
  );
}

export default PlanInfo;
