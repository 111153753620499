import React from "react";
import * as S from "./styles";

function Navigation(props) {
  const { currentStep, totalSteps } = props;

  const dots = [];

  for (let index = 1; index <= totalSteps; index++) {
    const isActive = currentStep === index;

    dots.push(
      <S.NavigationDot key={index} className={isActive && "active"}>
        &bull;
      </S.NavigationDot>
    );
  }

  return <S.NavigationWrapper>{dots}</S.NavigationWrapper>;
}

export default Navigation;
