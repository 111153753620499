import React from "react";
import ReactDOM from "react-dom";
import TagManager from "react-gtm-module";

import tagManagerConfig from "./config/tag-manager";
import App from "./App";

TagManager.initialize(tagManagerConfig);

ReactDOM.render(<App />, document.getElementById("root"));
