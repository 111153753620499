import React from "react";
import * as S from "./styles";

import loading from "../../assets/img/loader.gif";

function LoaderButton() {
  return (
    <S.LoaderWrapper>
      <S.LoaderImage src={loading} alt="Carregando informação" />
    </S.LoaderWrapper>
  );
}

export default LoaderButton;
