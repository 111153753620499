import React from "react";
import * as S from "./styles";

function ErrorMessage(props) {
  const { children } = props;

  return <S.ErrorMessageWrapper role="alert">{children}</S.ErrorMessageWrapper>;
}

export default ErrorMessage;
