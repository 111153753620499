import { toast } from "react-toastify";

const toastMessages = {
  success(message) {
    toast.success(message);
  },
  warning(message, options) {
    toast.warning(message, options);
  },
  error(message) {
    toast.error(message);
  },
};

export default toastMessages;
