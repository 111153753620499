import styled, { css } from "styled-components";
import MaskedInput from "react-input-mask";

const InputMaskWrapper = styled.div`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacings.xsmall};
  `}
`;

const InputMask = styled(MaskedInput)`
  ${({ theme }) => css`
    width: 100%;
    height: 50px;
    padding-left: ${theme.spacings.xsmall};
    border: 1px solid #ced4da;
    border-radius: 5px;

    :focus {
      border: 2px solid ${theme.colors.primary};
    }
  `}
`;

export { InputMaskWrapper, InputMask };
