import React from 'react'

import * as S from './styles'

export default function SubscriptionTastingMessage({
  initialDate,
  endDate,
  billingDate,
  paymentMethod,
  numberDays,
  subscriptionPlanWithCouponAmount,
  paymentFinish,
}) {
  let title = 'Você está prestes a iniciar o seu período de degustação'

  let daysWord = 'dias'

  if (numberDays === 1)
    daysWord = 'dia'

  let numberOfDays = `${numberDays} ${daysWord} grátis`

  let validPeriod = `Válido de ${initialDate} a ${endDate}`

  let charged = `O valor acima será cobrado em ${billingDate} e você pode cancelar a qualquer momento antes desta data`

  if (paymentMethod === 2) title =
    'O período de degustação só está disponível para pagamento com cartão de crédito.'

  if (paymentFinish) {
    title = `O período de degustação será de ${initialDate} a
      ${endDate} e a primeira cobrança está programada para ${billingDate}.`
  }

  return (
    <S.Container className={paymentFinish && 'payment-finish-wrapper'}>
      <S.TextWrapper>
        <S.TastingMessageInfoCircleFill />

        <S.Wrapper>
          <S.Title>{title}</S.Title>

          {paymentMethod !== 2 && !paymentFinish && (
            <S.Wrapper>
              <S.Text>{numberOfDays}</S.Text>
              <S.Text>{validPeriod}</S.Text>

              {!subscriptionPlanWithCouponAmount && (
                <S.Text>{charged}</S.Text>
              )}
            </S.Wrapper>
          )}
        </S.Wrapper>
      </S.TextWrapper>
    </S.Container>
  )
}
