import styled, { css } from "styled-components";

const SelectWrapper = styled.div`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacings.xsmall};
  `}
`;

const Select = styled.select`
  ${({ theme }) => css`
    width: 100%;
    height: 50px;
    padding-left: ${theme.spacings.xsmall};
    border: 1px solid #ced4da;
    border-radius: 5px;

    :focus {
      border: 2px solid ${theme.colors.primary};
    }
  `}
`;

const Option = styled.option`
  ${({ theme }) => css`
    width: 100%;
    height: 50px;
    padding-left: ${theme.spacings.xsmall};
    border: 1px solid #ced4da;
    border-radius: 5px;

    :focus {
      border: 2px solid ${theme.colors.primary};
    }
  `}
`;

export { SelectWrapper, Select, Option };
