import styled, { css } from "styled-components";

const DetailsWrapper = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacings.small} ${theme.spacings.small};
  `}
`;

const DetailsInfoWrapper = styled.div`
  text-align: center;
`;

const DetailsStatus = styled.h2`
  ${({ theme }) => css`
    margin-top: ${theme.spacings.xxsmall};
    font-size: ${theme.font.sizes.xlarge};
  `}
`;

const DetailsSubscriptionId = styled.p`
  ${({ theme }) => css`
    margin-top: ${theme.spacings.xsmall};
    font-size: ${theme.font.sizes.xsmall};
    opacity: 0.8;
  `}
`;

const DetailsCard = styled.div`
  ${({ theme }) => css`
    margin-top: ${theme.spacings.xsmall};
    padding: ${theme.spacings.xsmall};
    border-radius: ${theme.border.radius};
    background-color: #fff;
  `}
`;

const DetailsInfo = styled.div`
  ${({ theme }) => css`
    font-family: ${theme.font.familySecondary};
    font-size: ${theme.font.sizes.xsmall};

    :not(:last-child) {
      margin-bottom: ${theme.spacings.xxsmall};
    }
  `}
`;

const DetailsInfoStrong = styled.span`
  ${({ theme }) => css`
    font-weight: ${theme.font.bold};
  `}
`;

const DetailsButton = styled.button`
  ${({ theme }) => css`
    padding: ${theme.spacings.xsmall};
    border-radius: ${theme.border.radius};
    background-color: ${theme.colors["primary"]};
    font-weight: ${theme.font.bold};
    color: ${theme.colors.white};
    text-align: center;
    cursor: pointer;

    :not(:last-child) {
      margin-right: ${theme.spacings.xxsmall};
    }
  `}
`;

export {
  DetailsWrapper,
  DetailsInfoWrapper,
  DetailsStatus,
  DetailsSubscriptionId,
  DetailsCard,
  DetailsInfo,
  DetailsInfoStrong,
  DetailsButton,
};
