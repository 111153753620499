import React from 'react'

import * as S from './styles'

function YourInformationAccess(props) {
  const { title, description } = props

  return (
    <S.Container>
      <S.Text
        fontFamily
        fontLarge
        fontBold
        margin
      >{title}</S.Text>

      {description?.map((item) => (
        <S.Description>
          <S.Text fontFamily fontSmall>
            {item.lineOne}
            <b>{item.lineTwo}</b>
            {item.lineTree}
            <b>{item.lineFour}</b>
          </S.Text>
        </S.Description>
      ))}

      <S.Video>
        <iframe
          src="https://player.vimeo.com/video/343381693?h=cc81b44811&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
          frameborder="0" allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen title="Vídeo exemplo"></iframe>
      </S.Video>

      <script src="https://player.vimeo.com/api/player.js"></script>
    </S.Container>
  )
}

export default YourInformationAccess
