import React from "react";

function Layout(props) {
  const { children } = props;

  return (
    <>
      {/* <header>
        <h1>Coruja Sábia</h1>
      </header> */}

      <main>{children}</main>

      {/* <footer>Footer</footer> */}
    </>
  );
}

export default Layout;
