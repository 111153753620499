import styled, { css } from 'styled-components'
import { CheckCircle } from '@styled-icons/bootstrap'

const CouponRow = styled.div`
  ${({ theme }) => css`
    margin: 0;
    width: 100%;
    display: block;

    .coupon-row {
      margin: 0;

      &.coupon-row-step-1 {
        margin: ${theme.spacings.xsmall} 0
          ${theme.spacings.xsmall} 0;
      }
    }

    .coupon-active {
      padding: 10px 10px 0 10px;
      margin-bottom: ${theme.spacings.xxsmall};
      background: ${theme.colors.white};
      border: 1px solid ${theme.colors.input};
      border-radius: 5px;
    }

    .loading-active {
      div {
        min-height: auto;
      }

      img {
        width: 90px;
      }
    }
  `}
`

const CouponWrapper = styled.div`
  &.relative {
    position: relative;
  }

  &.discount-coupon .coupon-icon {
    ${({ theme }) => css`
      position: absolute;
      top: 13px;
      right: 20px;
      width: ${theme.font.sizes.small};
    `}
  }
`

const CouponInformation = styled.div`
  position: relative;
`

const CouponTextBase = styled.p`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacings.xxsmall};
    font-size: ${theme.font.sizes.xxsmall};
  `}

  &.title-discount-applied {
    ${({ theme }) => css`
      padding-bottom: 5px;
      margin-bottom: 10px;
      border-bottom: 1px solid ${theme.colors.input};
      display: block;
    `}
  }
`

const CouponText = styled(CouponTextBase)`
  display: flex;
  justify-content: space-between;
`

const CouponTextSecondary = styled(CouponTextBase)`
  text-align: right;
`

const CouponIcon = styled.div`
  width: 100%;
  display: block;
  cursor: pointer;

  &.applied {
    ${({ theme }) => css`
      color: ${theme.colors.secondary};
    `}
  }

  &.removed {
    ${({ theme }) => css`
      color: ${theme.colors.close};
    `}
  }
`

const CouponCheckCircleOutlined = styled(CheckCircle)`
  ${({ theme }) => css`
    width: ${theme.font.sizes.small};
    color: ${theme.colors.secondary};
  `}
`

export {
  CouponRow,
  CouponWrapper,
  CouponInformation,
  CouponText,
  CouponTextSecondary,
  CouponIcon,
  CouponCheckCircleOutlined,
}
