/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { Row, Col } from "react-flexbox-grid";
import Payment from "payment";

import CreditCard from "../CreditCard";
import StepsButton from "../StepsButton";

import Heading from "../../../../shared/Heading";
import Input from "../../../../shared/Input";
import InputMask from "../../../../shared/InputMask";
import Select from "../../../../shared/Select";

import useData from "../../../../hooks/useData";

import Coupon from './Coupon'
import SubscriptionTastingMessage from "../SubscriptionTastingMessage";
import DataLayer from '../DataLayer'

function PaymentForm(props) {
  const [focused, setFocused] = useState("");

  const { watch } = useFormContext();

  const {
    selectedSubscriptionPlan,
    getInstallmentsAmount,
    installmentsAmount,
    installmentSelected,
    getTastingValidate,
    tastingPlan,
    getCoupon,
    coupon,
    couponValue,
    subscriptionPlanWithCouponAmount,
    campaignName,
    studentFirstName,
    studentLastName,
    studentPhoneNumber,
    studentEmail,
  } = useData()

  const paymentMethod = parseInt(
    watch(
      "paymentMethodId",
      selectedSubscriptionPlan.allowCreditCardPayment ? 1 : 2
    )
  );

  const { currentStep, previousStep, nextStep } = props;

  const card = watch(["number", "name", "expiry", "cvc"]);

  useEffect(() => {
    if (currentStep === 3) {
      if (couponValue)
        getCoupon(selectedSubscriptionPlan.id, couponValue)

      async function getChangeValues() {
        await getInstallmentsAmount(selectedSubscriptionPlan.id, '')
        await getTastingValidate(selectedSubscriptionPlan.id)
      }

      if (selectedSubscriptionPlan.id && !couponValue)
        getChangeValues()
    }
  }, [currentStep, paymentMethod])

  function handleInputFocus(event) {
    setFocused(event.target.name);
  }

  function clearNumber(value = "") {
    return value.replace(/\D+/g, "");
  }

  function handleInputChange(event) {
    const { value } = event.target;

    if (!value) return value;

    const issuer = Payment.fns.cardType(value);
    const clearValue = clearNumber(value);

    let nextValue;

    switch (issuer) {
      case "amex":
        nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
          4,
          10
        )} ${clearValue.slice(10, 15)}`;
        break;

      case "dinersclub":
        nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
          4,
          10
        )} ${clearValue.slice(10, 14)}`;
        break;

      default:
        nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
          4,
          8
        )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`;
        break;
    }

    return nextValue.trim();
  }

  return (
    <>
      <Heading lineBottom>Pagamento</Heading>

      <Row start="md" center="xs">
        {selectedSubscriptionPlan.allowCreditCardPayment && (
          <Col xs={6} md={3}>
            <Input
              name="paymentMethodId"
              type="radio"
              defaultChecked={paymentMethod === 1}
              value={1}
            >
              Cartão de Crédito
            </Input>
          </Col>
        )}

        {selectedSubscriptionPlan.allowBanckSlipPayment && (
          <Col xs={6} md={3}>
            <Input
              name="paymentMethodId"
              type="radio"
              defaultChecked={paymentMethod === 2}
              value={2}
            >
              Boleto
            </Input>
          </Col>
        )}
      </Row>

      {paymentMethod === 1 && (
        <>
          <CreditCard
            number={card.number}
            name={card.name}
            expiry={card.expiry}
            cvc={card.cvc}
            focused={focused}
          />

          <Row>
            <Col md={6}>
              <Input
                name="name"
                placeholder="Titular do Cartão"
                onFocus={handleInputFocus}
              />
            </Col>

            <Col md={6}>
              <InputMask
                inputMode="numeric"
                name="holderCpf"
                placeholder="CPF"
                mask="999.999.999-99"
              />
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Input
                inputMode="numeric"
                name="number"
                placeholder="Número do Cartão"
                onChange={handleInputChange}
                onFocus={handleInputFocus}
              />
            </Col>

            <Col md={6}>
              <InputMask
                inputMode="numeric"
                name="expiry"
                placeholder="Validade do Cartão"
                mask="99/99"
                onFocus={handleInputFocus}
              />
            </Col>
          </Row>

          <Row style={{ margin: 0 }}>
            <Col md={6}>
              <InputMask
                inputMode="numeric"
                name="cvc"
                placeholder="Código do Cartão"
                mask="9999"
                onFocus={handleInputFocus}
              />
            </Col>

            <Col md={6}>
              <Select
                name="installments"
                placeholder="Parcelas"
                items={installmentsAmount}
              />
            </Col>

            {!!selectedSubscriptionPlan.id && (
              <Coupon subscriptionPlanId={selectedSubscriptionPlan.id} />
            )}

            {!!tastingPlan?.subscriptionPlanTrial && (
              <SubscriptionTastingMessage
                initialDate={tastingPlan.trialPeriodStart}
                endDate={tastingPlan.trialPeriodEnd}
                billingDate={tastingPlan.trialPeriodSubscriptionChargeDate}
                paymentMethod={paymentMethod}
                numberDays={tastingPlan.numberDays}
                subscriptionPlanWithCouponAmount={subscriptionPlanWithCouponAmount}
              />
            )}

          </Row>
        </>
      )}

      {paymentMethod === 2 && (
        <>
          <Coupon subscriptionPlanId={selectedSubscriptionPlan.id} />

          {!!selectedSubscriptionPlan.bankSlipPaymentInstructions && (
            <div style={{ margin: "1em" }}>
              {selectedSubscriptionPlan.bankSlipPaymentInstructions}
            </div>
          )}

          {!!tastingPlan?.subscriptionPlanTrial && (
            <SubscriptionTastingMessage
              paymentMethod={paymentMethod}
            />
          )}
        </>
      )}

      <Row end="xs" style={{ margin: 0 }}>
        <Col xs={12}>
          <Input type="checkbox" name="termsConditions">
            <span style={{ fontSize: 14 }}>
              Li e concordo com os{" "}
              <a href="https://corujasabia.com.br/termos-de-uso" target="_blank" rel="noopener noreferrer">
                termos e condições
              </a>
              .
            </span>
          </Input>
        </Col>
      </Row>

      <StepsButton
        currentStep={currentStep}
        previousStep={previousStep}
        nextStep={nextStep}
        style={{ margin: 0 }}
      />

      {currentStep === 3 && (
        <DataLayer
          campaign={campaignName}
          subscriptionPlanInfo={{
            'subscriptionPlanId': selectedSubscriptionPlan.id,
            'subscriptionPlanName': selectedSubscriptionPlan.name,
            'subscriptionPlanValue': selectedSubscriptionPlan.amountWithDescount ?
              selectedSubscriptionPlan.amountWithDescount : selectedSubscriptionPlan.amount
          }}
          studentInfo={{
            'studentFirstName': studentFirstName,
            'studentLastName': studentLastName,
            'studentPhoneNumber': studentPhoneNumber,
            'studentEmail': studentEmail
          }}
          paymentInfo={{
            'paymentMethod': paymentMethod === 1 ? 'credit_card' : 'boleto',
            'paymentInstallments':
              installmentSelected ?
                paymentMethod === 2 ? '1' :
                  installmentSelected
              : '1',
            'paymentDiscountCoupon': couponValue,
            'paymentDiscountValue': coupon?.discountCouponAmount,
            'paymentTotalPaid': coupon ? coupon?.subscriptionPlanWithCouponAmount :
              selectedSubscriptionPlan.amountWithDescount ?
                selectedSubscriptionPlan.amountWithDescount : selectedSubscriptionPlan.amount
          }}
        />
      )}
    </>
  );
}

export default PaymentForm;
