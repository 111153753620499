import React from 'react'

import VivoValorize from '../LandingPages/vivoValorize'
import SuperReview from '../LandingPages/superReview'
import Conexia from '../LandingPages/conexia'
import SocioCeara from '../LandingPages/socioCeara'
import SocioBahia from '../LandingPages/socioBahia'

function CouponRedemption({ planId, campaign }) {
  return (
    <>
      {campaign === 'vivo-valoriza' && (
        <VivoValorize
          planId={planId}
          campaign={campaign}
        />
      )}

      {campaign === 'super-revisao' && (
        <SuperReview
          planId={planId}
          campaign={campaign}
        />
      )}

      {campaign === 'conexia' && (
        <Conexia
          planId={planId}
          campaign={campaign}
        />
      )}

      {campaign === 'socio-ceara' && (
        <SocioCeara
          planId={planId}
          campaign={campaign}
        />
      )}

      {campaign === 'socio-bahia' && (
        <SocioBahia
          planId={planId}
          campaign={campaign}
        />
      )}
    </>
  )
}

export default CouponRedemption
