import styled, { css } from 'styled-components'
import media from 'styled-media-query'

import banner from './assets/images/banner-bg-inicio-julho.png'
import bannerMobile from './assets/images/banner-bg-inicio-julho-mobile.png'

const Container = styled.div`
  ${({ theme }) => css`
    width: ${theme.width.large};

    .message-alert {
      padding: 0 ${theme.spacings.medium};
      margin: 0 auto;
      max-width: ${theme.width.xxlarge};
      color: ${theme.colors.close};
      text-align: left;
    }

    .information-access .information-access-wrapper {
      margin-top: ${theme.spacings.small};
    }

    .information-access .text {
      width: ${theme.width.large};
      display: block;
    }

    .want-more {
      margin-top: ${theme.spacings.small};
    }

    .row-background {
      margin-bottom: ${theme.spacings.small};
      width: 100vw;
      height: 96.7vh;
      background: url(${banner}) ${theme.colors.primary} no-repeat top center;
      background-size: ${theme.width.large};
      display: flex;
      justify-content: center;
      align-items: center;
    }

    ${media.lessThan('medium')`
      .message-alert {
        padding: ${theme.spacings.empty};
        margin-bottom: ${theme.spacings.xsmall};
        max-width: ${theme.width.large};
      }

      .information-access {
        margin-top: ${theme.spacings.xsmall};
      }

      .want-more {
        margin-top: ${theme.spacings.empty};
      }

      .row-background {
        margin-bottom: ${theme.spacings.empty};
        width: ${theme.width.auto};
        height: ${theme.width.large};
        background-image: url(${bannerMobile});
        background-size: auto;
      }

      .row-background p {
        font-size: ${theme.font.sizes.xxsmall};
      }
    `}

    ${media.greaterThan('1350px')`
      .row-background {
        height: 85.7vh;
        background-size: ${theme.width.auto};
      }
    `}

    ${media.greaterThan('1450px')`
      .row-background {
        height: 96.7vh;
        background-size: ${theme.width.large};
      }
    `}

    ${media.greaterThan('1700px')`
      .row-background {
        margin-bottom: ${theme.spacings.large};
        height: 100vh;
      }
    `}

    ${media.between('751px', '1349px')`
      .row-background {
        background-size: ${theme.width.auto};
      }
    `}
  `}
`

const Wrapper = styled.div`
  ${media.lessThan('medium')`
    ${({ theme }) => css`
      padding: ${theme.spacings.small};

      h1 {
        margin-bottom: ${theme.spacings.xsmall};
        font-size: ${theme.font.sizes.large};
      }

      .box-layout input {
        padding: ${theme.spacings.xsmall};
        max-width: ${theme.width.large};
      }

      button {
        margin-top: ${theme.spacings.empty};
        padding: ${theme.spacings.xxsmall};
        max-width: ${theme.width.xlarge};
      }
    `}
  `}
`

const TextMain = styled.h1`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacings.medium};
    color: ${theme.colors.white};
    font-size: ${theme.font.sizes.xxlarge};
  `}
`

const Text = styled.p`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacings.xsmall};
    color: ${(props) => props.colorBlack ? `${theme.colors.dark}` :
      (props.colorLight ? `${theme.colors.textLight}` : `${theme.colors.white}`)};
    font-size: ${(props) => props.fontLarge ? `${theme.font.sizes.large}` :
      (props.fontSmall ? `${theme.font.sizes.small}` :
        (props.fontXlarge ? `${theme.font.sizes.xlarge}` : `${theme.font.sizes.medium}`))};
    font-weight: ${(props) => props.fontBold ? `${theme.font.bold}` : ''};
    ${(props) => props.fontFamily ? `font-family: ${theme.font.familySecondary}` : ''};

    &.text-discount {
      margin: 0 auto;
      margin-bottom: ${theme.spacings.xxsmall};
      max-width: ${theme.width.xhalf};
      width: ${theme.width.large};
    }

    ${media.lessThan('medium')`
      &.text-discount {
        margin-bottom: ${theme.spacings.xsmall};
        max-width: ${theme.width.xlarge};
      }
    `}

    ${media.greaterThan('1700px')`
      &.text-discount {
        max-width: ${theme.width.xxxhalf};
      }
    `}
  `}
`

const Image = styled.img`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacings.medium};
    width: ${theme.width.medium};

    ${media.lessThan('medium')`
      margin-bottom: ${theme.spacings.xxsmall};
      width: ${theme.width.small};
    `}
  `}
`

const ButtonBase = styled.button`
  ${({ theme }) => css`
    width: ${theme.width.large};
    padding: ${theme.spacings.xsmall};
    border-radius: ${theme.border.radiusXlarger};
    font-size: ${theme.font.sizes.medium};
    font-weight: ${theme.font.bold};
    text-transform: uppercase;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  `}
`;

const Button = styled(ButtonBase)`
  ${({ theme }) => css`
    margin: ${theme.spacings.medium} auto;
    width: ${theme.width.large};
    max-width: ${theme.width.half};
    color: ${theme.colors.white};
    background: ${theme.colors.secondary};

    :disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }

    ${media.greaterThan('1700px')`
      max-width: ${theme.width.xxhalf};
    `}
  `}
`;

const InputWrapper = styled.div`
  ${({ theme }) => css`
    &.alert-error .box-layout input {
      border-color: ${theme.colors.close};
    }
  `}
`

export {
  Container,
  Wrapper,
  TextMain,
  Text,
  Image,
  Button,
  InputWrapper,
}
