import styled, { css } from "styled-components";

const HeaderWrapper = styled.header`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 45px;
    background-color: ${theme.colors.primary};
    font-size: 0;
  `}
`;

const HeaderImage = styled.img`
  width: 35px;
`;

export { HeaderWrapper, HeaderImage };
