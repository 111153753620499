/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { Row, Col } from 'react-flexbox-grid'

import { PlusCircle, DashCircle } from '@styled-icons/bootstrap'

import useData from '../../../../../hooks/useData'

import Loader from '../../../../../shared/Loader'
import Input from '../../../../../shared/Input'
import toast from '../../../../../shared/Toast'

import formatCurrency from '../../../../../utils/format-currency'

import * as S from './styles'

export default function Coupon({ subscriptionPlanId, currentStep }) {
  const {
    getInstallmentsAmount,
    getTastingValidate,
    getCoupon,
    loading,
    coupon,
    couponValue,
    setCoupon,
    setCouponValue,
    subscriptionPlanWithCouponAmount,
    installmentInUntil,
    setInstallmentInUntilCheck,
    installmentInUntilCheck,
  } = useData()

  const planId = subscriptionPlanId

  useEffect(() => {
    setInstallmentInUntilCheck(false)

    if (coupon?.subscriptionPlanWithCouponAmount > 0)
      setInstallmentInUntilCheck(true)
  }, [coupon, installmentInUntil])

  async function handleApplyCoupon(data, action) {
    if (data) await getCoupon(planId, data, 'toastCouponActive')

    if (!data || action === 'removed') {
      setCoupon('')
      setCouponValue('')
      await getInstallmentsAmount(planId, '')
      await getTastingValidate(planId, '')
    }

    if (action === 'removed') toast.warning('Cupom excluído')
  }

  return (
    <S.CouponRow>
      <Row className={`coupon-row ${currentStep === 1 && 'coupon-row-step-1'}`}>
        <Col md={6}>
          {!subscriptionPlanWithCouponAmount && currentStep !== 1 && (
            <S.CouponWrapper className="relative discount-coupon">
              <Input
                name="discountCouponCode"
                placeholder="Cupom de Desconto"
                value={couponValue}
                onChange={(e) => setCouponValue(e.target.value)}
                onBlur={(e) => handleApplyCoupon(e.target.value)}
              />

              {!coupon && !couponValue && (
                <S.CouponIcon
                  className="coupon-icon applied"
                  onClick={() => handleApplyCoupon(couponValue || '')}
                >
                  <PlusCircle />
                </S.CouponIcon>
              )}

              {!!couponValue && (
                <S.CouponIcon
                  className="coupon-icon removed"
                  onClick={() => handleApplyCoupon('', 'removed')}
                >
                  <DashCircle />
                </S.CouponIcon>
              )}
            </S.CouponWrapper>
          )}
        </Col>

        <Col md={6} className={loading ? 'loading-active' : ''}>
          {loading ? (
            <Loader />
          ) : (
            !!coupon && (
              <S.CouponWrapper className="coupon-active">
                <S.CouponInformation>
                  <S.CouponText className="title-discount-applied">
                    <S.CouponCheckCircleOutlined /> Desconto aplicado
                  </S.CouponText>

                  <S.CouponText>
                    Valor da Assinatura{' '}
                    <b>R$ {formatCurrency(coupon.subscriptionPlanAmount)}</b>
                  </S.CouponText>

                  <S.CouponText>
                    Desconto{' '}
                    <b>R$ {formatCurrency(coupon.discountCouponAmount)}</b>
                  </S.CouponText>

                  <S.CouponText>
                    Total{' '}
                    <b>
                      R${' '}
                      {formatCurrency(coupon.subscriptionPlanWithCouponAmount)}
                    </b>
                  </S.CouponText>

                  {!!installmentInUntilCheck && installmentInUntil && (
                    <S.CouponTextSecondary>
                      em até {installmentInUntil[0]} {installmentInUntil[1]}{' '}
                      <b>R$ {formatCurrency(installmentInUntil[2])}</b>
                    </S.CouponTextSecondary>
                  )}
                </S.CouponInformation>
              </S.CouponWrapper>
            )
          )}
        </Col>
      </Row>
    </S.CouponRow>
  )
}
