import React from "react";
import * as S from "./styles";

function Heading(props) {
  const { lineBottom, children } = props;

  return <S.Wrapper lineBottom={lineBottom}>{children}</S.Wrapper>;
}

export default Heading;
