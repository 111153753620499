const theme = {
  grid: {
    container: {
      large: "130rem",
      small: "90rem",
    },
    gutter: "3.2rem",
  },
  border: {
    radius: ".8rem",
    radiusXlarger: "4rem",
    radiusSmall : "1rem"
  },
  font: {
    family: "Futura PT",
    familySecondary: "Open Sans",
    bold: 600,
    sizes: {
      xxsmall: "1.4rem",
      xsmall: "1.6rem",
      small: "1.8rem",
      medium: "2.2rem",
      large: "2.6rem",
      xlarge: "3.4rem",
      x1large: "4.3rem",
      xxlarge: "5.2rem",
    },
  },
  colors: {
    primary: "#403edc",
    secondary: "#46d83f",
    dark: "#383838",
    background: "#f0f2f5",
    text: "#030517",
    white: "#fff",
    black: "#000",
    grayLight : "#707070",
    input: '#ced4da',
    close: '#ff4d4f',
    textLight: "#525252",
    bgGreen: "#f6ffed",
    borderGreen: "#b7eb8f",
    highlighted: "#ffc200",
  },
  spacings: {
    auto: "auto",
    empty: "0",
    xxsmall: "0.8rem",
    xsmall: "1.6rem",
    small: "2.4rem",
    medium: "3.2rem",
    large: "6.0rem",
    xlarge: "6.4rem",
    x1large: "9.8rem",
    xxlarge: "12.8rem",
  },
  width: {
    auto: "auto",
    small: "6rem",
    medium: "10rem",
    xxlarge: "80%",
    xlarge: "90%",
    large: "100%",
    xxxhalf: "21%",
    xxhalf: "30%",
    xhalf: "33.333%",
    half: "50%",
  },
  height: {
    small: "2.4rem",
  }
};

export default theme;
