import React from "react";

import Header from "../../shared/Header";
import Container from "../../shared/Container";

function TermsConditions() {
  return (
    <>
      <Header />

      <Container size="small">
        Termos de Uso Muito bem-vindo ao Coruja Sábia. Apresentamos os termos
        para acesso a plataforma e seus serviços. Podemos alterar este termo a
        qualquer momento, mediante publicação das alterações em nosso site.
        Todas as alterações passam a valer automaticamente 30 (trinta) dias após
        serem publicadas em nosso site, e valem imediatamente para todos os
        usuários que se registrarem após sua publicação. Esse Termo é válido
        para todos os usuários de nossa Plataforma. ACEITAÇÃO AOS TERMOS DE USO:
        1.1 Ao fazer o cadastro em nossa Plataforma, você concorda, expressa e
        voluntariamente, com todas as cláusulas destes Termos de Uso. Ao acessar
        e/ou usar os serviços do Coruja Sábia, você concorda com os Termos de
        Uso, suas cláusulas e condições, conforme abaixo detalhadas. 1.2 Você
        está autorizado a utilizar os Serviços do Coruja Sábia exclusivamente se
        você concorda com todas regras e condições estabelecidas nos Termos de
        Uso. 1.3 Se você não concorda com qualquer informação destes Termos de
        Uso, você não está autorizado a acessar ou utilizar os Serviços e
        Conteúdos oferecidos em nossa Plataforma. A utilização dos Serviços e
        conteúdos do Coruja Sábia está expressamente dependente do seu
        consentimento às regras dos Termos de Uso, conforme solicitado quando do
        seu cadastro em nossa Plataforma. USUÁRIOS E MEMBROS: 2.1. Usuários. O
        uso das áreas públicas do corujasabia.com.br está disponível para
        qualquer indivíduo, mesmo que sem registro. Para poder desfrutar de
        qualquer serviço privado, o usuário deve adquirir um plano para se
        tornar um Membro do Coruja Sábia. No tocante a esse Termo, a palavra
        “Usuário” se referirá a qualquer Usuário que não tenha se registrado
        como Membro do Coruja Sábia. O “Usuário Free” tem o direito de usar a
        plataforma, no entanto, estes terão acesso somente aos conteúdos e
        serviços “gratuitos”, não tendo direito a acessar qualquer outro tipo de
        conteúdo e serviço. 2.2. Membros. No tocante a esse Termo, a palavra
        “Membro” se referirá a qualquer um que tenha se registrado em nosso
        site, ou seja, adquirido um plano. Nós temos o direito, a nosso
        critério, de suspender ou encerrar o seu uso da nossa Plataforma e de
        recusar todo e qualquer uso corrente ou futuro de todas ou algumas
        partes de nossa Plataforma. 2.3. Senha e Segurança. Quando você
        completar o seu processo de registro, você criará uma senha que
        habilitará seu acesso à nossa Plataforma Coruja Sábia. Você concorda em
        manter a confidencialidade da sua senha, e é inteiramente responsável
        por qualquer dano resultante da não manutenção dessa confidencialidade e
        todas as atividades que ocorrerem através do uso de sua senha. Você
        deverá nos notificar imediatamente de qualquer acesso não autorizado de
        sua senha ou outra quebra de segurança. Você concorda que o
        corujasabia.com.br não pode e não será responsabilizado por qualquer
        perda ou dano que ocorra a partir do não cumprimento com o disposto
        nessa seção. 2.3.1. Do uso da senha, cadastro e segurança. A
        conta/cadastro e a senha são de USO INDIVIDUAL e NÃO DEVEM SER
        COMPARTILHADAS, sendo vedada a utilização de uma única conta da
        Plataforma para mais de um aluno e, de instituições educacionais sem
        prévia autorização da equipe Coruja Sábia. Caso a mesma senha/login seja
        utilizada ao mesmo tempo em dois computadores diferentes, o Coruja Sábia
        pode cancelar o plano adquirido não ocorrendo devolução do valor pago.
        Neste caso, o assinante terá que efetuar novo cadastro e efetuar novo
        pagamento para assistir às aulas. PAGAMENTO, RENOVAÇÃO, CANCELAMENTO E
        REEMBOLSO: 3.1 Você concorda em realizar o pagamento pelo Plano de
        Serviços contratado que não seja disponibilizado de forma gratuita pelo
        Coruja Sábia, a fim de ter acesso aos serviços pagos do Coruja Sábia,
        conforme descritos em nosso site. Os novos Membros deverão realizar o
        pagamento do plano solicitado através do site do Coruja Sábia, ou, caso
        disponível, pelo aplicativo em seu smartphone ou tablet. 3.2 Você deverá
        fornecer, manter e atualizar informações verdadeiras, exatas, atuais e
        completas sobre si mesmo, conforme for solicitado por nossos processos
        de. Você não poderá de forma alguma se passar por outra pessoa física ou
        jurídica ou adulterar sua identidade ou sua afiliação a qualquer pessoa
        física ou jurídica, incluindo o uso do nome de usuário, senha ou de
        outra informação de conta de outra pessoa física ou jurídica ou o nome,
        a semelhança, voz, imagem ou fotografia de outra pessoa. Você não poderá
        fornecer detalhes falsos sobre você, sobre seus pais ou responsáveis.
        3.3 Transações bancárias e através de cartão de crédito. Durante sua
        inscrição, as informações do seu cartão de crédito ou de sua conta
        bancária podem ser necessárias. Como Membro, você concorda que sejam
        cobrados no seu cartão de crédito ou debitados na sua conta bancária os
        preços relativos aos planos e todas as taxas e impostos sobre vendas e
        quaisquer outras despesas aplicáveis ao uso dos Serviços. 3.4 Pagamentos
        via boleto. Você se compromete em efetuar o pagamento à vista do boleto
        bancário com o valor referente ao plano solicitado. Caso não seja
        efetuado o pagamento até a data de vencimento do boleto, você concorda
        que não será gerada outra via, nem será liberado o acesso. 3.5 O Coruja
        Sábia reserva-se o direito de alterar os preços publicados no website a
        qualquer momento, sem aviso prévio. A alteração de preço não se aplicará
        aos contratos em vigor, podendo ser aplicada apenas às renovações do
        contrato, mediante concordância do Membro. 3.6 Uma vez efetivada a
        assinatura e realizado o pagamento (à vista ou primeira parcela), este
        termo poderá ser rescindido pelo contratante, por desistência, no prazo
        de até 07 (sete) dias após APROVAÇÃO da compra, nos moldes do Código de
        Defesa do Consumidor, tendo direito ao reembolso completo do valor pago.
        Não há, após este prazo, devolução de qualquer valor, tenham ou não sido
        utilizados os serviços existentes, pois o cliente ao aceitar este
        contrato de licença afirma ter testado e aprovado o sistema e verificado
        que este atende às suas necessidades. 3.7 Para o cancelamento. O membro
        deverá encaminhar um e-mail para contato@corujasabia.com.br solicitando
        o cancelamento com os seguintes dados: e-mail de cadastro e últimos 4
        dígitos do cartão de crédito. Em caso de compra pelo boleto deve ser
        informado o e-mail de cadastro, nome completo, CPF, banco, agência e
        conta corrente. O prazo para a empresa realizar o estorno em ambos os
        casos é de até 60 dias. Uma vez efetuado o cancelamento e solicitado
        reembolso por parte da empresa, a transferência de valores se torna de
        responsabilidade do banco. SUAS INFORMAÇÕES: 4.1. Definições.(i) “Suas
        Informações” são definidas como qualquer informação postada por você ou
        que você nos dê (direta ou indiretamente), inclusive através do seu
        processo de registro ou do seu uso de nossa Plataforma, comentários em
        blogs, mensagens enviadas dentro de nossa Plataforma, ou e-mail. Você é
        o único responsável por Suas Informações, e nós agimos como um canal
        passivo para a distribuição e publicação de suas Informações Públicas
        (como definidas abaixo).(ii) Seu nome e localidade, bem como, qualquer
        parte de Suas Informações que, através do uso de nossa Plataforma ou de
        outra maneira, você envie ou disponibilize para a inclusão em áreas
        públicas de nosso site são referidas como “Informações Públicas”;
        qualquer outra parte de Suas Informações será referida como “Informação
        Privada”.(iii) “Áreas públicas” do nosso site são aquelas que são
        acessíveis para alguns ou todos os nossos Membros (p.ex., não restritas
        à sua visualização apenas) ou para o público geral. 4.2. Acessibilidade
        das Informações Públicas. Você deve entender que as suas Informações
        Públicas podem ser acessíveis e publicadas por programas de publicação
        automática e por ferramentas de busca, ferramentas de metabusca,
        crawlers, metacrawlers e outros similares. 4.3 Restrições. Ao considerar
        o uso de nossa Plataforma, você concorda que as Suas Informações: (a)
        não devem ser fraudulentas; (b) não devem infringir nenhum direito de
        cópia de terceiros, patente, direito de distribuição, ou outro direito
        de propriedade, de publicação ou privacidade; (c) não devem violar
        nenhuma lei, estatuto, ordenação ou regulamento; (d) não devem ser
        difamatórias, caluniosas, injuriosas, ameaçadoras ou abusivas; (e) não
        devem ser obscenas ou conter pornografia, pornografia infantil, ou fotos
        de pessoas despidas; (f) não devem conter vírus, cavalos de Tróia,
        worms, time bombs, cancelbots, easter eggs ou outras rotinas de
        programação que possam danificar interferir, interceptar ou desapropriar
        qualquer sistema, dado ou informação pessoal; (g) não devem nos
        responsabilizar ou fazer com que percamos (total ou parcialmente) o
        serviço do nosso Provedor de Internet ou outros fornecedores; (h) não
        devem criar links direta ou indiretamente a qualquer material que você
        não tenha direito de incluir ou linkar. Em adição, você deve nos
        informar um endereço válido de e-mail, tanto na hora de seu registro
        conosco quanto a cada vez que o seu e-mail mudar. Você concorda também
        que o seu perfil de membro, postagens de comentários em blogs, uploads
        de fotos ou quaisquer porções do website reservadas apenas a uso dos
        Membros não devem ser usadas por você para atividades comerciais, vendas
        de bens e serviços, ou a promoção de uma companhia, bem ou serviço não
        relacionado ao tópico ou espírito do Coruja Sábia ou do Grupo de Membros
        em particular. Você será exclusivamente responsável pelas informações
        que você postar nas áreas publicamente acessáveis da Plataforma,
        independente de sua intenção ou não de fazê-lo. 4.4. Licença. Nós não
        clamamos propriedade de Suas Informações. Nós usaremos as suas
        Informações Pessoais Identificáveis apenas de acordo com a nossa
        Política de Privacidade. Entretanto, para nos autorizar a usar suas
        Informações Públicas e para nos assegurar de que não violamos nenhum
        direito que você possa ter sobre suas Informações Públicas, você garante
        ao corujasabia.com.br um direito não exclusivo, mundial, perpétuo,
        irrevogável, royalty-free, sub-licenciável de exercer, comercializar e
        explorar todos os direitos de cópia, publicidade, e direitos de base de
        dados (mas não outros direitos) que você possua em suas Informações
        Públicas, em qualquer mídia conhecida ou inventada a partir de já. 4.5.
        Restrições no Nosso Uso de Suas Informações. Exceto se especificado
        contrariamente em nossa política de privacidade, nós não venderemos,
        alugaremos ou divulgaremos nenhuma parte de suas Informações Pessoais
        Identificáveis (como definidas em nossa política de privacidade) sobre
        você (incluindo seu endereço de e-mail) para terceiros. 4.6.
        Consentimento de Divulgação. Você entende e concorda que o
        corujasabia.com.br pode divulgar Suas Informações se requerida a fazê-lo
        por lei ou por acreditar de boa fé que essa divulgação é razoável e
        necessária para: (a) cooperar com um procedimento judicial, uma ordem
        judicial ou processo legal sobre nós ou nosso website;(b) reforçar os
        Termos de Serviço;(c) replicar a respeito do infringimento do direito de
        terceiros pelas Suas Informações;(d) proteger os direitos, propriedades
        ou a segurança pessoal do corujasabia.com.br, seus empregados, usuários
        e público. USO DA PLATAFORMA: 5.1. Responsabilidade e Controle. Você é
        inteiramente responsável por todas as Suas Informações que você faça
        upload, poste, envie por e-mail ou de alguma outra forma torne
        disponível via nossa Plataforma (site ou aplicativo). Nós não
        controlamos suas Informações Públicas ou as Informações Públicas de
        outros Membros ou postadas por eles, e não garantimos a veracidade,
        integridade ou a qualidade de Suas Informações ou das Informações
        postadas por ou sobre outros Membros, nem endossamos nenhuma opinião
        expressada por você ou outros membros. Você entende que, usando nossa
        Plataforma, você poderá ser exposto a informações ofensivas, indecentes.
        Nós não temos a obrigação de monitorar, nem tomamos a menor
        responsabilidade pelas Suas Informações, pelas Informações Públicas ou
        informações a respeito de ou postada por outros usuários. Você concorda
        que sob nenhuma circunstância o Coruja Sábia, seus diretores, sócios ou
        funcionários poderão ser responsabilizados ou julgados por quaisquer
        informações, incluindo, mas não se limitando a, erros ou omissões nas
        Suas Informações ou nas Informações Públicas postadas por ou sobre
        outros Membros, por nenhuma perda ou dano de qualquer tipo ocorrido como
        resultado do uso das Suas Informações ou de quaisquer Informações
        Públicas de ou sobre outros Membros que venha a ser postada, enviada por
        e-mail, transmitida ou disponibilizada de qualquer outra maneira
        conectada à nossa Plataforma, ou por qualquer falha em corrigir ou
        remover quaisquer dessas informações. 5.2 Ao aceitar estes Termos de
        Uso, você tem o direito não exclusivo, intransferível, não
        sub-licenciável e limitado de entrar em nossa Plataforma, acessar e usar
        os Serviços, UNICAMENTE PARA USO PESSOAL E NÃO COMERCIAL. 5.3 Todos os
        direitos não previstos expressamente nestes Termos de Uso estão
        reservados ao Coruja Sábia. 5.4 Você concorda que os Serviços são para o
        seu uso pessoal e não comercial e que ninguém além de você usará os
        Serviços. Você não tem direitos de cópia, reprodução ou alteração no
        todo ou em parte, de qualquer parte dos Serviços e Conteúdos de
        propriedade do Coruja Sábia. 5.5 Além da licença limitada de uso
        estabelecida nestes Termos de Uso, você não tem qualquer outro direito,
        título ou propriedade sobre os Serviços. Você entende e reconhece que,
        em quaisquer circunstâncias, os seus direitos com relação ao Serviços
        serão limitados pelos direitos autorais e/ou leis de propriedade
        intelectual aplicáveis e ainda por estes Termos de Uso. 5.6 Os Serviços
        permitem que os Membros tenham acesso à Plataforma do Coruja Sábia,
        troquem mensagens com outros Membros, se conectem com professores
        contratados independentes (os “Professores”), que fornecem instruções ao
        vivo e/ou gravadas. 5.7 Você concorda que todas as informações e
        mensagens (perguntas e respostas) postadas na “Comunidade” podem ser
        acessadas e lidas por qualquer usuário, free e/ou membro do Coruja
        Sábia. Somente membros ativos podem fazer perguntas e respondê-las. O
        Coruja Sábia não se responsabiliza pela veracidade e qualidade das
        perguntas e respostas. A equipe do Coruja Sábia, tem o direito de
        moderar as perguntas e respostas, podendo excluí-las a qualquer momento,
        de acordo com seus próprios critérios. Da mesma forma, as “lives”
        permitem que membros façam perguntas e forneçam respostas (podendo estas
        ser acessadas por usuários e membros), sendo que o Coruja Sábia não se
        responsabiliza pela qualidade e veracidade destas informações, podendo
        moderar e excluir perguntas e respostas a qualquer momento. Os mesmos
        critérios servem para as videoaulas. 5.8 O Coruja Sábia poderá modificar
        os Serviços e/ou Conteúdo ou descontinuar a sua disponibilidade a
        qualquer momento. 5.9 VOCÊ É O ÚNICO RESPONSÁVEL pela obtenção,
        pagamento e manutenção de todos os serviços de telefonia, acesso à
        internet, plano de dados, tarifas e/ou outras taxas, mensalidades e
        custos associados ao seu acesso e uso dos Serviços, bem como pelo
        software, hardware de computador, telefone celular, smartphone e outros
        equipamentos necessários para o uso e acesso aos Serviços. Caso altere
        ou desative seu número de telefone celular, você deverá atualizar as
        informações de sua conta no Coruja Sábia dentro de 48 horas para
        garantir que suas mensagens não sejam enviadas para a pessoa que
        adquirir seu número antigo. Você consente e fornece todos os direitos
        necessários para permitir que os usuários sincronizem seus dispositivos
        com quaisquer informações visíveis (através do aplicativo também) para
        eles no Coruja Sábia. 5.10 Outras disposições contidas nestes Termos de
        Uso, os Serviços estão disponíveis para seu uso por um período que
        começa a partir do registro de seus dados junto com o pagamento
        correspondente ao plano escolhido, incluindo as suas renovações. 5.11
        Você não deve tentar, apoiar nem incentivar as tentativas de terceiros
        para driblar, reverter a engenharia, decodificar, descompilar, desmontar
        ou fraudar ou interferir de qualquer forma com aspectos dos Serviços e
        Conteúdos. Você não deve distribuir, intercambiar, modificar, vender ou
        revender ou retransmitir a qualquer pessoa qualquer parte dos Serviços,
        incluindo, mas não se limitando, a qualquer texto, vídeo, imagem ou
        áudio, para qualquer finalidade empresarial comercial ou pública. Você
        concorda em não copiar, vender, distribuir ou transferir o Conteúdo e/ou
        Serviços do Coruja Sábia, estando ciente que poderá, você, ser
        processado caso o faça. CONDIÇÕES PARA SUSPENSÃO OU ENCERRAMENTO.6.1 Não
        impedindo possíveis adições neste Termo, os seguintes tipos de ação são
        passíveis de suspensão ou encerramento imediato do seu status de Membro
        do Coruja Sábia: (a) O uso de nossa Plataforma para: (i) ameaçar ou
        intimidar outra pessoa de qualquer forma, incluindo a restrição ou
        inibição do uso de nossa Plataforma; (ii) personificar qualquer pessoa
        (incluindo a equipe do corujasabia.com.br ou outros Membros), ou atestar
        falsamente representação de qualquer pessoa, física ou jurídica, através
        do uso de endereços de e-mail similares, apelidos, ou a criação de
        contas falsas ou qualquer outro método ou procedimento; (iii) disfarçar
        a origem de quaisquer Informações Públicas que sejam transmitidas a
        terceiros; (iv) perseguir ou perturbar outrem; (v) coletar ou armazenar
        dados pessoais de outros usuários; (b) Postar quaisquer Informações
        Públicas ou outro material: (i) que seja ilegal, ofensivo, ameaçador,
        abusivo, perturbador, difamatório, intimidador, vulgar, obsceno,
        profano, acusatório, que invada a privacidade de outrem (inclusive a
        postagem de e-mails privados ou informações de contato de outros
        usuários), odioso, ou racialmente, eticamente ou de outra forma
        contestável, incluindo quaisquer Informações Públicas ou outro material
        que possa ser considerado um discurso de ódio; (ii) que seja obsceno,
        pornográfico ou adulto por natureza; (iii) que você não tenha o direito
        de disponibilizar por qualquer lei ou por contrato; (iv) que infrinja
        qualquer patente, marca registrada, segredo comercial, direito de cópia
        ou quaisquer outros direitos proprietários ou relacionamentos
        fiduciários; (v) que seja qualquer tipo de propaganda ou material
        promocional não solicitados, ou qualquer outra forma de solicitação
        (incluindo, mas não se limitando a, “spam”, “junk mail”, e correntes de
        e-mail); ou (vi) que seja de qualquer outra forma inapropriado ou
        postado de má fé; (c) Encorajar outrem a violar esses Termos; (d) Se
        recusar a seguir instruções da equipe do corujasabia.com.br; (e) Violar
        (intencional ou não intencionalmente) estes Termos, ou qualquer lei,
        ordenação, estatuto ou regulamento local, estadual, nacional ou
        internacional aplicável. Mesmo que nós proibamos o conteúdo e as
        condutas acima, você entende e concorda que você poderá vir a ser
        exposto a tais condutas e conteúdos e que usa a Plataforma a seu próprio
        risco. Além disso, você entende que nós nos reservamos o direito de
        suspender Membros que estejam inativos por um período extenso de tempo.
        TERMOS GERAIS: Para os propósitos destes Termos, “postar” inclui o
        upload, a postagem, envio de e-mails, transmissão ou disponibilização de
        alguma outra forma. Sem se limitar aos precedentes, o corujasabia.com.br
        e seus designados têm o direito de remover quaisquer Informações
        Públicas ou outro material que viole esses Termos ou seja de outra
        maneira questionável. NÃO-INTERFERÊNCIA COM A PLATAFORMA: 8.1 Você
        concorda que não irá: (a) fazer upload, postar, enviar por e-mail ou
        transmitir de qualquer outra forma rotinas de programação, arquivos ou
        programas com a intenção de interromper, destruir ou limitar as
        funcionalidades de qualquer software ou hardware ou equipamento de
        telecomunicação; (b) interferir com ou perturbar nossa Plataforma ou com
        as redes conectadas ao nosso website através do uso de nossa Plataforma,
        ou desobedecer quaisquer requerimentos, procedimentos, políticas ou
        regulamentos das redes conectadas ao nosso website, ou de alguma outra
        maneira interferir com a nossa Plataforma em qualquer sentido, incluindo
        através do uso de Javascript, ou outros códigos; (c) agir de qualquer
        maneira que imponha uma carga excessivamente pesada, que seja
        desproporcional ou não razoável, em nossa infraestrutura; (d) copiar,
        reproduzir, alterar, modificar ou exibir publicamente qualquer
        informação que esteja disponível em nosso website (exceto as Suas
        Informações), ou criar trabalhos derivados do nosso website (exceto Suas
        Informações), com o entendimento de que tais ações constituiriam
        infringimento de direitos de cópia ou outro tipo de violação à
        propriedade intelectual do corujasabia.com.br ou quaisquer terceiros,
        exceto se autorizado por escrito e com antecedência pelo
        corujasabia.com.br ou pelo terceiro apropriado; Práticas Gerais de Uso
        da Plataforma.Você percebe e entende que nós podemos estabelecer
        práticas e limites gerais no que diz respeito ao uso de nossa
        Plataforma. Você entende que nós não nos responsabilizamos ou podemos
        ser responsabilizados pelo armazenamento ou apagamento, ou pela falha em
        armazenar ou apagar, qualquer uma de Suas Informações. Em adição, você
        entende que nós nos reservamos o direito de mudar as práticas listadas
        neste “Termo de Uso” e limites gerais a qualquer momento, a nosso
        critério, com ou sem aviso. Comunicados da Equipe corujasabia.com.br.
        Você entende e concorda que nós podemos enviar certos comunicados, como
        anúncios de serviços do corujasabia.com.br e newsletters, bem como
        ofertas de bens e serviços relevantes e que beneficiem você ou qualquer
        grupo de Membros unidos por afinidade que você possa vir a participar, e
        que esses comunicados são considerados parte de nossa Plataforma.
        Privacidade. O corujasabia.com.br regula internamente suas políticas de
        privacidade e apura se nossas práticas estão de acordo com a política
        publicada. Links. Nós podemos providenciar, ou terceiros podem
        providenciar, links para outros websites ou recursos. Por não termos
        controle sobre tais websites ou recursos, você entende e concorda que
        nós não somos responsáveis pela disponibilidade de tais websites e
        recursos, e nós não endossamos e não somos responsabilizados ou
        passíveis de sermos responsabilizados por qualquer conteúdo,
        publicidade, produto, ou outro material disponível neste ou através
        deste website ou recurso. Você também entende e concorda que o
        corujasabia.com.br não será responsável ou poderá ser responsabilizado,
        direta ou indiretamente, por qualquer perda ou dano causado ou alegado
        de ter sido causado por ou conectado ao uso de qualquer conteúdo, bem ou
        serviço disponível em ou através do uso de qualquer um desses websites
        ou recursos. Indenização Você concorda indenizar o Portal Coruja Sábia,
        seus diretores, sócios, agentes, funcionários, consultores, afiliados,
        subsidiários e parceiros e mantê-los livres de qualquer declaração ou
        demanda, incluindo despesas de advogados feitas por terceiros devido ao
        ou derivado de um descumprimento seu destes Termos ou dos documentos que
        ele incorpora como referência, o seu uso da Plataforma, Suas
        Informações, sua violação de qualquer lei, estatuto, ordem ou
        regulamento ou os direitos de terceiros. GARANTIAS E RESPONSABILIDADES.
        14.1. Isenção de Garantias. Você usa a nossa Plataforma a seu próprio e
        exclusivo risco. Nossa Plataforma é oferecida a você “como é” e “como
        está disponível”. Nós nos isentamos de garantias e condições de qualquer
        tipo, sejam expressas, implícitas ou instituídas, incluindo mas não se
        limitando a garantias implicadas de mercantibilidade, adaptação a um
        propósito particular e não infringimento. Nós nos isentamos de quaisquer
        garantias relacionadas à segurança, confiabilidade, conveniência e
        desempenho de nossa Plataforma. Isentamo-nos de quaisquer garantias
        sobre informações ou conselhos obtidos através de nossa Plataforma.
        Isentamo-nos de quaisquer garantias por serviços ou bens recebidos
        através ou anunciados em nossa Plataforma ou recebidos através de links
        exibidos em nossa Plataforma, bem como por qualquer informação ou
        conselho recebido através de quaisquer links exibidos em nossa
        Plataforma. Também nos isentamos de qualquer prestação de serviço que
        não esteja disponível na Plataforma. Isentamo-nos de tirar dúvidas
        referentes às disciplinas veiculadas e qualquer outra área de
        conhecimento, pelo simples fato do usuário ser um Membro. Em adição,
        nenhum conselho ou informação (oral ou escrita) obtida por você, através
        de nossa equipe, criará qualquer tipo de garantia. 14.2. Suposição de
        Risco. Você entende e concorda que você faz download ou obtém outra
        forma de acesso ao nosso material ou dados através do uso de nossa
        Plataforma ao seu próprio critério e risco e que você será o único
        responsável por quaisquer danos ao seu computador ou perda de dados que
        resulte do download deste material ou dados. 14.3. Limitação de
        Responsabilidade. Você concorda que em nenhuma circunstância o
        corujasabia.com.br poderá ser responsabilizado por nenhum dano direto,
        indireto, incidental, especial, consequencial ou exemplar, incluindo mas
        não se limitando a, danos por perda de lucros, boa vontade, uso, dados
        ou outras perdas intangíveis (mesmo se o corujasabia.com.br tenha sido
        alertado sobre a possibilidade de tais danos), resultando de ou
        conectados à nossa Plataforma ou a estes Termos ou à incapacidade de
        usar a nossa Plataforma (apesar de resultando de seu uso, incluindo
        negligência), resultando de ou conectado a qualquer transação com
        terceiros ou resultando de ou conectado ao seu uso de nossa Plataforma.
        RESOLUÇÃO DE DISPUTAS. 15.1. Processos. As partes tentarão resolver de
        boa fé através de negociação qualquer disputa, alegação ou controvérsia
        gerada a partir ou relacionada a esses Termos, incluindo os documentos
        incorporados como referência, o seu uso da Plataforma, Suas Informações,
        sua violação de qualquer lei ou dos direitos de terceiros. Caso essa
        disputa, alegação ou controvérsia não seja resolvida pela negociação, as
        partes escolhem os Tribunais Estaduais e Federais localizados na Comarca
        de Ribeirão Preto/SP, como o único lugar onde se dará qualquer disputa
        judicial. 15.2. Negociação. Qualquer uma das partes poderá iniciar uma
        negociação através de um aviso escrito à outra parte, avisando o motivo
        da disputa e a solução desejada. O destinatário desse aviso deverá
        responder por escrito dentro de 15 (quinze) dias úteis com uma
        declaração de sua posição e a sua solução recomendada. Se a disputa não
        for resolvida através dessa troca de correspondência, representantes das
        duas partes com autoridade para tal devem se reunir pessoalmente ou de
        alguma outra forma em um local e data mutuamente acordados dentro de 30
        (trinta) dias úteis a partir do primeiro contato, com o intuito de
        trocar informações e perspectivas relevantes, e para tentar resolver a
        disputa. 15.3. Disputa de Resoluções pelo corujasabia.com.br para o
        Benefício de Membros. Nós poderemos tentar a ajudar Membros a resolver
        disputas. Nós o faremos a nosso critério, e não temos a obrigação de
        tentar ajudar a resolver disputas entre Membros. No caso de tentarmos
        resolver disputas, o faremos de boa fé baseados somente nas regras
        gerais e nos padrões da Plataforma, e não faremos julgamentos a respeito
        de questões ou alegações legais. Encerramento.Você concorda que nós, ao
        nosso critério, podemos disparar um alerta, suspender temporariamente,
        indefinidamente ou remover completamente qualquer conteúdo ou informação
        postada por você, ou encerrar o seu status de Membro ou a sua capacidade
        de uso total ou parcial de nossa Plataforma, por qualquer razão,
        incluindo, mas não se limitando a: (a) falta de uso; (b) se nós
        acreditarmos que você violou ou agiu de forma inconsistente com o
        espírito destes Termos ou dos documentos incorporados como referência;
        (c) se não formos capazes de verificar ou autenticar qualquer informação
        que você nos forneça; (d) se acreditarmos que suas ações possam gerar
        responsabilidade legal a você, aos nossos usuários ou a nós. Você
        concorda que o encerramento da sua conta ou do acesso total ou parcial à
        Plataforma por motivo esclarecido nesses Termos poderá ser efetuado, sem
        aviso prévio, e entende e concorda que poderemos imediatamente desativar
        ou apagar a sua conta e todas as informações e arquivos relativos a ela
        e/ou barrar acesso futuro à nossa Plataforma. Proibição de Revenda.Você
        concorda em não reproduzir, duplicar, copiar, vender, revender ou
        explorar com quaisquer fins comerciais, qualquer porção da Plataforma
        corujasabia.com.br, o uso da plataforma, ou o acesso à plataforma para o
        fim de vendas de bens ou serviços, ou a promoção de uma companhia, bem
        ou serviço.
      </Container>
    </>
  );
}

export default TermsConditions;
