import React, { useEffect, useState, useCallback } from 'react'

function DataLayer({
  campaign,
  subscriptionPlanInfo,
  studentInfo,
  paymentInfo,
}) {
  const [subscriptionPlan, setSubscriptionPlan] = useState({})
  const [student, setStudent] = useState({})
  const [payment, setPayment] = useState({})

  const {
    subscriptionPlanId,
    subscriptionPlanName,
    subscriptionPlanValue
  } = subscriptionPlanInfo
  const {
    studentFirstName,
    studentLastName,
    studentPhoneNumber,
    studentEmail
  } = studentInfo
  const {
    paymentMethod,
    paymentInstallments,
    paymentDiscountCoupon,
    paymentDiscountValue,
    paymentTotalPaid
  } = paymentInfo

  useEffect(() => {
    setSubscriptionPlan({
      campaign: campaign ? `"${campaign}"` : null,
      id: subscriptionPlanId ? `"${subscriptionPlanId}"` : null,
      name: subscriptionPlanName ? `"${subscriptionPlanName}"` : null,
      value: subscriptionPlanValue || subscriptionPlanValue === 0 ? `"${subscriptionPlanValue}"` : null,
    })

    setStudent({
      firstName: studentFirstName ? `"${studentFirstName}"` : null,
      lastName: studentLastName ? `"${studentLastName}"` : null,
      phoneNumber: studentPhoneNumber ? `"${studentPhoneNumber}"` : null,
      email: studentEmail ? `"${studentEmail}"` : null,
    })

    setPayment({
      method: paymentMethod ? `"${paymentMethod}"` : null,
      installments: paymentInstallments ? `"${paymentInstallments}"` : null,
      discountCoupon: paymentDiscountCoupon ? `"${paymentDiscountCoupon}"` : null,
      discountValue: paymentDiscountValue ? `"${paymentDiscountValue}"` : null,
      totalPaid: paymentTotalPaid || paymentTotalPaid === 0 ? `"${paymentTotalPaid}"` : null,
    })
  }, [campaign, payment.discountCoupon, payment.discountValue,
    payment.installments, payment.method,
    payment.totalPaid, paymentDiscountCoupon,
    paymentDiscountValue, paymentInstallments, paymentMethod,
    paymentTotalPaid, student.email, student.firstName, student.lastName,
    student.phoneNumber, studentEmail, studentFirstName, studentLastName,
    studentPhoneNumber, subscriptionPlan.campaign, subscriptionPlan.id,
    subscriptionPlan.name, subscriptionPlan.value, subscriptionPlanId,
    subscriptionPlanName, subscriptionPlanValue
  ])

  const loadScript = useCallback(async (script) => {
    if (script) {
      const scriptValidRemove = document.getElementById('data-layer')

      if (scriptValidRemove) scriptValidRemove.remove()

      script.id = 'data-layer'

      script.innerHTML = `
        window.dataLayer = window.dataLayer || []

        window.dataLayer.push(
          {
            campaign: ${subscriptionPlan.campaign},

            subscriptionPlan: {
              id: ${subscriptionPlan.id},
              name: ${subscriptionPlan.name},
              value: ${subscriptionPlan.value}
            },

            student: {
              email: ${student.email},
              firstName: ${student.firstName},
              lastName: ${student.lastName},
              phoneNumber: ${student.phoneNumber}
            },

            payment: {
              method: ${payment.method},
              installments: ${payment.installments},
              discountCoupon: ${payment.discountCoupon},
              discountValue: ${payment.discountValue},
              totalPaid: ${payment.totalPaid}
            }
          }
        )
      `
    }

    document.getElementsByTagName('head')[0].appendChild(script)
  }, [
    payment.discountCoupon, payment.discountValue, payment.installments,
    payment.method, payment.totalPaid, student.email, student.firstName,
    student.lastName, student.phoneNumber, subscriptionPlan.campaign, subscriptionPlan.id,
    subscriptionPlan.name, subscriptionPlan.value
  ])

  useEffect(() => {
    let script = document.createElement('script')

    script.type = 'text/javascript'

    loadScript(script)
  }, [loadScript])

  return (
    <></>
  )
}

export default DataLayer
