import styled, { css } from 'styled-components'
import media from 'styled-media-query'

import banner from './assets/images/banner-bg-inicio.png'

const Container = styled.div`
  ${({ theme }) => css`
    width: ${theme.width.large};

    .message-alert {
      padding: 0 ${theme.spacings.medium};
      margin: 0 auto;
      max-width: ${theme.width.xxlarge};
      color: ${theme.colors.close};
      text-align: left;
    }

    .information-access .information-access-wrapper {
      margin-top: ${theme.spacings.small};

    }

    .information-access .text {
      width: ${theme.width.large};
      display: block;
    }

    .want-more {
      margin-top: ${theme.spacings.small};
    }

    .row-background {
      margin-bottom: ${theme.spacings.small};
      width: 100vw;
      min-height: 96.7vh;
      background: url(${banner}) ${theme.colors.primary} no-repeat center;
      background-size: cover;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    ${media.lessThan('medium')`
      .message-alert {
        padding: ${theme.spacings.empty};
        margin-bottom: ${theme.spacings.xsmall};
        max-width: ${theme.width.large};
      }

      .information-access {
        margin-top: ${theme.spacings.xsmall};
      }

      .want-more {
        margin-top: ${theme.spacings.empty};
      }

      .row-background {
        margin-bottom: ${theme.spacings.empty};
        width: ${theme.width.auto};
        min-height: ${theme.width.auto};
      }

      .row-background p {
        font-size: ${theme.font.sizes.xxsmall};
      }
    `}

    ${media.greaterThan('1350px')`
      .row-background {
        min-height: 93.7vh;
        background-size: ${theme.width.auto};
      }
    `}

    ${media.greaterThan('1450px')`
      .row-background {
        min-height: 85vh;
        background-size: ${theme.width.large};
      }
    `}

    ${media.greaterThan('1700px')`
      .row-background {
        margin-bottom: ${theme.spacings.large};
        min-height: 100vh;
      }
    `}

    ${media.between('751px', '1349px')`
      .row-background {
        background-size: ${theme.width.auto};
      }
    `}
  `}
`

const Wrapper = styled.div`
  ${media.lessThan('medium')`
    ${({ theme }) => css`
      padding: ${theme.spacings.small};

      h1 {
        margin-bottom: ${theme.spacings.xsmall};
        font-size: ${theme.font.sizes.large};
      }

      .box-layout input {
        padding: ${theme.spacings.xsmall};
        max-width: ${theme.width.large};

      }

      button {
        margin-top: ${theme.spacings.medium};
        padding: ${theme.spacings.xxsmall};
        /* max-width: ${theme.width.xlarge}; */
      }
    `}
  `}
  ${media.greaterThan('large')`
      ${({theme}) => css`
      margin : ${(props) => props.withMargin && `${theme.spacings.large} 0`};
      `}
    `}
`

const TextMain = styled.h1`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacings.xxsmall};
    color: ${theme.colors.white};
    font-size: ${theme.font.sizes.x1large};
    font-weight: ${(props) => props.fontBold ? `${theme.font.bold}` : ''};
    ${(props) => props.fontFamily ? `font-family: ${theme.font.family}` : ''};
  `}
`
const TextSubTextMain = styled.h1`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacings.xsmall};
    color: ${theme.colors.white};
    font-size: ${theme.font.sizes.large};
    font-weight: ${(props) => props.fontBold ? `${theme.font.bold}` : ''};
    ${(props) => props.fontFamily ? `font-family: ${theme.font.family}` : ''};
  `}

`

const Text = styled.p`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacings.xsmall};
    color: ${(props) => props.colorTextDefault ? `${theme.colors.grayLight}` :
      (props.colorLight ? `${theme.colors.textLight}` : `${theme.colors.white}`)};
    font-size: ${(props) => props.fontLarge ? `${theme.font.sizes.large}` :
      (props.fontSmall ? `${theme.font.sizes.small}` :
      props.font2xSmall ? `${theme.font.sizes.xxsmall}` :
      (props.fontXlarge ? `${theme.font.sizes.x1large}` : `${theme.font.sizes.medium}`))};
    font-weight: ${(props) => props.fontBold ? `${theme.font.bold}` : ''};
    ${(props) => props.fontFamily ? `font-family: ${theme.font.family}` : ''};

    b {
        color: ${(props) => props.boldHighlight && '#5FCC58' }
    }

    &.text-discount {
      margin: 0 auto;
      margin-bottom: ${theme.spacings.xxsmall};
      max-width: ${theme.width.xhalf};
      width: ${theme.width.large};
    }

    ${media.lessThan('medium')`
      &.text-discount {
        margin-bottom: ${theme.spacings.xsmall};
        max-width: ${theme.width.xlarge};
      }
    `}

    ${media.greaterThan('1700px')`
      &.text-discount {
        max-width: ${theme.width.xxxhalf};
      }
    `}
  `}
`

const Image = styled.img`
  &{
    cursor: ${(props) => props.cursorON && 'pointer'}
  }

  ${({ theme }) => css`
    margin-bottom: ${theme.spacings.medium};
    width: ${theme.width.medium};

    ${media.lessThan('medium')`
      margin-bottom: ${theme.spacings.xxsmall};
      width: ${theme.width.small};
    `}
  `}
`

const ButtonBase = styled.button`
  ${({ theme }) => css`
    width: ${theme.width.large};
    padding: ${theme.spacings.xsmall};
    border-radius: ${theme.border.radiusSmall};
    font-size: ${theme.font.sizes.medium};
    font-weight: ${theme.font.bold};
    text-transform: uppercase;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  `}
`;

const Button = styled(ButtonBase)`
  ${({ theme }) => css`
    margin: ${theme.spacings.medium} auto;
    width: ${theme.width.large};
    max-width: ${theme.width.half};
    color: ${theme.colors.white};
    /* ${theme.colors.secondary}; */

    :disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }

    ${media.greaterThan('1700px')`
      max-width: ${theme.width.xxhalf};
    `}
  `}
  background: #5FCC58;
`;

const InputWrapper = styled.div`
  ${({ theme }) => css`
    &.alert-error .box-layout input {
      border-color: ${theme.colors.close};
    }
  `}
`

   const CardContainer = styled.div`
      display: flex;
      justify-content:space-around;
      align-items:baseline;
      gap:10rem;
`
    const CardContent = styled.div`
              display:flex;
              flex-direction:column;
              justify-content:center;
              align-items: center;
              gap:2rem;
              `
    const CardImage = styled.div`
              display:flex;
              justify-content:center;
              align-items: center;
              height: 149px;
              width:149px;
              border:5px solid #EDEDED;
              border-radius:50% ;
              box-shadow:5px 5px 20px #00000014;
              margin:0;

              img {
                  height:61px;
                  width:68px;
                  margin:0;
              }

`

    const CardTitle = styled.h3`

        ${({theme}) => css`
        color: ${theme.colors.grayLight};
        font-size:${theme.font.sizes.medium};
        margin-top:${theme.spacings.small};
        `}

`
    const AppsBox = styled.div`
      display:flex;
      gap:1rem;
      justify-content:center;


    `

    const Footer = styled.div`
        background-color: #4454CD;
        display:flex;
        justify-content: center;
        align-items:center;
        flex:1;
        border-top: 1px solid #FFFFFF34;
        height:99px
    `



export {
  Container,
  Wrapper,
  TextMain,
  TextSubTextMain,
  Text,
  Image,
  Button,
  InputWrapper,
  CardContainer,
  CardContent,
  CardImage,
  CardTitle,
  AppsBox,
  Footer
}
