import React from "react";
import { useFormContext } from "react-hook-form";
import { Row, Col } from "react-flexbox-grid";

import StepsButton from "../StepsButton";

import Heading from "../../../../shared/Heading";
import Input from "../../../../shared/Input";
import InputMask from "../../../../shared/InputMask";
import Select from "../../../../shared/Select";

import brazilStates from "../../../../utils/brazil-states";

function AddressForm(props) {
  const { trigger } = useFormContext();

  const { currentStep, previousStep, nextStep } = props;

  async function validateInputs() {
    return trigger([
      "cep",
      "address",
      "addressNumber",
      "addressComplement",
      "addressDistrict",
      "addressCity",
      "addressState",
    ]);
  }

  return (
    <>
      <Heading lineBottom>Endereço de Cobrança</Heading>

      <Row>
        <Col xs={12} md={6}>
          <InputMask
            inputMode="numeric"
            name="cep"
            placeholder="CEP"
            mask="99999-999"
          />
        </Col>

        <Col xs={12} md={6}>
          <Input name="address" placeholder="Endereço" />
        </Col>
      </Row>

      <Row>
        <Col xs={12} md={6}>
          <Input
            inputMode="numeric"
            name="addressNumber"
            placeholder="Número"
          />
        </Col>

        <Col xs={12} md={6}>
          <Input name="addressComplement" placeholder="Complemento" />
        </Col>
      </Row>

      <Row>
        <Col xs={12} md={6}>
          <Input name="addressDistrict" placeholder="Bairro" />
        </Col>

        <Col xs={12} md={6}>
          <Input name="addressCity" placeholder="Cidade" />
        </Col>
      </Row>

      <Row>
        <Col xs={12} md={6}>
          <Select name="addressState" items={brazilStates} />
        </Col>
      </Row>

      <StepsButton
        validateInputs={validateInputs}
        currentStep={currentStep}
        previousStep={previousStep}
        nextStep={nextStep}
      />
    </>
  );
}

export default AddressForm;
