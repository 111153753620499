import React from "react";
import { useFormContext } from "react-hook-form";

import useData from '../../hooks/useData'

import * as S from "./styles";

import ErrorMessage from "../ErrorMessage";

function Select(props) {
  const { register, errors } = useFormContext();

  const { name, placeholder, items } = props;

  const { setInstallmentSelected } = useData()

  return (
    <S.SelectWrapper>
      <S.Select
        name={name}
        defaultValue=""
        ref={register}
        onChange={
          (e) => name === 'installments' &&
            setInstallmentSelected(e.target.value)
        }
      >
        {placeholder && (
          <S.Option value="" disabled>
            {placeholder}
          </S.Option>
        )}

        {items.map((item) => (
          <S.Option key={item.value} value={item.value}>
            {item.description}
          </S.Option>
        ))}
      </S.Select>

      {errors[name] && <ErrorMessage>{errors[name]["message"]}</ErrorMessage>}
    </S.SelectWrapper>
  );
}

export default Select;
