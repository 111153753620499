import styled, { css } from "styled-components";
import { Eye, EyeSlash } from "@styled-icons/bootstrap";
import { darken } from "polished";

const InputWrapper = styled.div`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacings.xsmall};

    &.box-layout input {
      padding: ${theme.spacings.medium};
      margin: 0 auto;
      max-width: ${theme.width.xxlarge};
      font-size: ${theme.font.sizes.xsmall};
      border-radius : ${theme.border.radiusXlarger}

    }
  `}
`;

const Input = styled.input`
  ${({ theme }) => css`
    display: block;
    width: 100%;
    height: 50px;
    padding-left: ${theme.spacings.xsmall};
    border: 1px solid #ced4da;
    border-radius: 5px;

    :focus {
      border: 2px solid ${theme.colors.primary};
    }
  `}
`;

const InputCheckbox = styled.input`
  ${({ theme }) => css`
    margin-right: ${theme.spacings.xxsmall};
  `}
`;

const InputVisilibityWrapper = styled.span`
  float: right;
  position: relative;
  margin-top: -38px;
  margin-right: 16px;
  cursor: pointer;
`;

const InputVisilibityEye = styled(Eye)`
  ${({ theme }) => css`
    width: ${theme.font.sizes.xsmall};
    color: ${theme.colors.dark};

    :hover {
      color: ${darken(0.4, `${theme.colors.dark}`)};
    }
  `}
`;

const InputVisilibityEyeSlash = styled(EyeSlash)`
  ${({ theme }) => css`
    width: ${theme.font.sizes.xsmall};
    color: ${theme.colors.dark};

    :hover {
      color: ${darken(0.4, `${theme.colors.black}`)};
    }
  `}
`;

export {
  InputWrapper,
  Input,
  InputCheckbox,
  InputVisilibityWrapper,
  InputVisilibityEye,
  InputVisilibityEyeSlash,
};
