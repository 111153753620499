import * as yup from 'yup'

import api from '../../services/api'

let preventEmailValidation = {}

async function emailExists(email) {
  if (email === preventEmailValidation.value)
    return preventEmailValidation.valid

  if (email) {
    const response = await api.get('/student/check-email', {
      params: {
        email,
      },
    })

    const uniqueEmail = !response.data.emailExists

    preventEmailValidation = {
      value: email,
      valid: uniqueEmail,
    }

    return uniqueEmail
  }
}

const schemaCampaign = yup.object().shape({
  firstName: yup.string().required('Nome obrigatório.'),
  lastName: yup.string().required('Sobrenome obrigatório.'),
  email: yup
    .string()
    .required('E-mail obrigatório.')
    .email('E-mail inválido.')
    .test('emailExists', 'E-mail já cadastrado.', emailExists),
  password: yup
    .string()
    .required('Senha obrigatória.')
    .min(6, 'Senha deve possuir no mínimo 6 caracteres.'),
  termsConditions: yup
    .boolean()
    .oneOf([true], 'Termos e Condições obrigatório.'),
})

export default schemaCampaign
