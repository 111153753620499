import { useState, useEffect, useCallback } from "react";

function useSessionStorage(key, defaultValue = null) {
  const [value, setValue] = useState(() => {
    const storagedValue = sessionStorage.getItem(key);
    return storagedValue ? JSON.parse(storagedValue) : null;
  });

  useEffect(() => {
    if (defaultValue) {
      sessionStorage.setItem(key, JSON.stringify(defaultValue));
      setValue(defaultValue);
    }
  }, [defaultValue, key]);

  const updateValue = useCallback(
    (newValue) => {
      sessionStorage.setItem(key, JSON.stringify(newValue));
      setValue(newValue);
    },
    [key]
  );

  const removeValue = useCallback(() => {
    sessionStorage.removeItem(key);
    setValue(null);
  }, [key]);

  return [value, (newValue) => updateValue(newValue), () => removeValue()];
}

export default useSessionStorage;
