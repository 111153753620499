import styled, { css } from 'styled-components'

const ErrorWrapper = styled.div`
  ${({ theme }) => css`
    &.exists-error div {
      margin-bottom: 0;
    }

    &.exists-error a {
      margin-top: ${theme.spacings.xxsmall};
      margin-bottom: ${theme.spacings.xsmall};
      color: ${theme.colors.primary};
      font-size: ${theme.font.sizes.xxsmall};
      display: block;
    }
  `}
`

export { ErrorWrapper }
