import React, { useEffect } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import useData from "./hooks/useData";
import Checkout from "./pages/Checkout";
import Details from "./pages/Details";
import TermsConditions from "./pages/TermsConditions";
import Layout from "./pages/_layouts";
import Loader from "./shared/Loader";

function Routes() {
  const { loading, getBanner, getSubscriptionPlans } = useData();

  const location = useLocation();

  useEffect(() => {
    getBanner();
    getSubscriptionPlans();
  }, [getBanner, getSubscriptionPlans]);

  if (loading && location.pathname !== "/finalizar-assinatura")
    return <Loader />;

  return (
    <Layout>
      <Switch>
        <Route path="/finalizar-assinatura" exact component={Checkout} />
        <Route path="/termos-condicoes" component={TermsConditions} />
        <Route path="/detalhes-assinatura" component={Details} />

        <Route
          path="*"
          render={() => window.location.href = 'https://www.corujasabia.com.br/'}
        />
      </Switch>
    </Layout>
  );
}

export default Routes;
