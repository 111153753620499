/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from 'react'
import { Row, Col } from 'react-flexbox-grid'

import useData from '../../../hooks/useData'

import Input from '../../../shared/Input'
import YourInformationAccess from './yourInformationAccess'
import PlanBenefits from './components/planBenefits'

import imageLogo from '../../../assets/img/coruja-sabia-logo.svg'

import * as S from './styles'

function SuperReview(props) {
  const { planId, campaign } = props

  const [plan] = useState(planId)
  const [discountCouponCode, setDiscountCouponCode] = useState('')
  const [couponCheck, setCouponCheck] = useState(false)
  const [alertError, setAlertError] = useState(false)

  const {
    loading,
    getCoupon,
    coupon,
    couponInvalid,
    setCouponInvalid,
  } = useData()

  const checkButtons = useCallback((couponCode, type, emptyInvalid) => {
    setAlertError(false)

    if ((discountCouponCode && [undefined].includes(couponCode, type)) ||
      (discountCouponCode === '' && type === 'empty'))
      setAlertError(true)

    if (couponInvalid)
      setAlertError(true)

    if (emptyInvalid)
      setAlertError(false)
  }, [coupon, couponInvalid, discountCouponCode])

  useEffect(() => {
    setCouponCheck(false)

    if (discountCouponCode)
      checkButtons(discountCouponCode, '')

    if (coupon) {
      setCouponInvalid(false)
      setAlertError(false)
      setCouponCheck(true)
    }
  }, [coupon])

  useEffect(() => {
    if (couponInvalid)
      checkButtons(discountCouponCode, 'invalid')

    if (!couponInvalid)
      checkButtons('', '', 'emptyInvalid')
  }, [couponInvalid])

  useEffect(() => {
    if (!loading && couponCheck)
      window.location.href = `/finalizar-assinatura?cart=${plan}&promo=${discountCouponCode}&campaignName=${campaign}`
  }, [loading, couponCheck])

  async function handleCouponCheck(value) {
    setDiscountCouponCode(value)

    if (value) {
      await getCoupon(plan, discountCouponCode, 'toastCouponActive')
    } else {
      checkButtons(value, 'empty')
      setCouponInvalid(false)
    }
  }

  function handleClickButtonScroll() {
    window.open('https://www.corujasabia.com.br/#hs_cos_wrapper_widget_1633612721189', '_blank')
  }

  return (
    <S.Container>
      <Row start="xs" center="xs" className="row-background">
        <Col xs={12} sm={12} md={12} lg={6}>
          <S.Wrapper>
            <S.Image src={imageLogo} title="Logo Coruja Sábia" />

            <S.TextMain>
              Experimente a melhor <S.Highlighted>Super Revisão <S.Br className="plain-text">por 7 dias sem pagar nada!</S.Br></S.Highlighted>
            </S.TextMain>

            <S.Text fontFamily>Comece inserindo o código do seu cupom:</S.Text>

            <S.InputWrapper className={alertError ? 'alert-error' : ''}>
              <Input
                name="discountCouponCode"
                placeholder="Informe o código do seu cupom"
                value={discountCouponCode}
                onChange={(e) => setDiscountCouponCode(e.target.value)}
                layout
              />
            </S.InputWrapper>

            <S.Button onClick={() => handleCouponCheck(discountCouponCode)}>
              Começar agora
            </S.Button>
          </S.Wrapper>
        </Col>
      </Row>

      <S.Wrapper>
        <Row start="xs" center="xs" className="information-access">
          <Row className="information-access-wrapper">
            <Col xs={12} sm={12} md={12} lg={12}>
              <YourInformationAccess
                title="Conheça nosso modelo de aulas:"
                description={[
                  {
                    'lineOne': 'Método',
                    'lineTwo': ' exclusivo',
                    'lineTree': ' que permite a compactação de todo o conteúdo em apenas',
                    'lineFour': ' 3 minutos.',
                  }
                ]}
              />
            </Col>
          </Row>
        </Row>

        <S.Wrapper>
          <Row start="xs" center="xs" className="information-access">
            <Row className="information-access-wrapper">
              <Col xs={12} sm={12} md={12} lg={12}>
                <PlanBenefits />
              </Col>
            </Row>
          </Row>
        </S.Wrapper>
      </S.Wrapper>

      <S.Wrapper>
        <Row start="xs" center="xs" className="want-more">
          <Col xs={12} sm={12} md={12} lg={6}>
            <S.Text
              fontBold
              fontLarge
              colorBlack
              className="text"
            >
              Como funciona?
            </S.Text>

            <S.Text
              fontFamily
              colorLight
              fontSmall
            >
              Nesta parceria você utilizará o cupom para ganhar <b>7 dias de degustação </b>
              sem pagar nada. Após este período, você será <S.Br>cobrado o valor do plano
                automaticamente a não ser que opte pelo cancelamento.</S.Br>
            </S.Text>

            <S.Button onClick={handleClickButtonScroll}>
              Começar agora
            </S.Button>
          </Col>
        </Row>
      </S.Wrapper>
    </S.Container>
  )
}

export default SuperReview
