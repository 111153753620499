import React, { createContext, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";

import useSessionStorage from "../hooks/useSessionStorage";
import toast from '../shared/Toast'

import api from "../services/api";

import formatCurrency from "../utils/format-currency";

const DataContext = createContext();

function DataProvider(props) {
  const [loading, setLoading] = useState(false);
  const [installmentsAmount, setInstallmentsAmount] = useState([])
  const [installmentInUntil, setInstallmentInUntil] = useState('')
  const [installmentInUntilCheck, setInstallmentInUntilCheck] = useState(false)
  const [installmentSelected, setInstallmentSelected] = useState('')

  const [
    storageSubscriptionPlan,
    setStorageSubscriptionPlan,
  ] = useSessionStorage("@CorujaSabia:SelectedSubscriptionPlan");

  const [storageStudyPlan, setStorageStudyPlan] = useSessionStorage(
    "@CorujaSabia:SelectedStudyPlan"
  );

  const [storageIdSubscription, setStorageIdSubscription] = useSessionStorage(
    "@CorujaSabia:IdSubscription"
  );

  const [banner, setBanner] = useState(null);

  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [selectedSubscriptionPlan, setSelectedSubscriptionPlan] = useState(
    storageSubscriptionPlan ? storageSubscriptionPlan : null
  );

  const [studyPlans, setStudyPlans] = useState([]);
  const [selectedStudyPlan, setSelectedStudyPlan] = useState(
    storageStudyPlan ? storageStudyPlan : null
  );

  const [idSubscription, setIdSubscrition] = useState(
    storageIdSubscription ? storageIdSubscription : null
  );

  const [checkoutFinishStep, setCheckoutFinishStep] = useState(false)

  const [tastingPlan, setTastingPlan] = useState([])

  const [coupon, setCoupon] = useState('')
  const [couponValue, setCouponValue] = useState('')
  const [couponInvalid, setCouponInvalid] = useState(false)
  const [subscriptionPlanWithCouponAmount, setSubscriptionPlanWithCouponAmount] = useState(false)
  const [campaignName, setCampaignName] = useState('')

  const [studentFirstName, setStudentFirstName] = useState('')
  const [studentLastName, setStudentLastName] = useState('')
  const [studentPhoneNumber, setStudentPhoneNumber] = useState('')
  const [studentEmail, setStudentEmail] = useState('')

  const history = useHistory();

  const { children } = props;

  const getBanner = useCallback(async () => {
    setLoading(true);

    const response = await api.get("/banner/active");
    setLoading(false);

    setBanner(response.data[0]);
  }, []);

  const getSubscriptionPlans = useCallback(async () => {
    setLoading(true);

    const response = await api.get("/subscriptionplan/active");

    const formattedData = response.data.map((dataItem) => {
      return {
        ...dataItem,
        displayTheme:
          dataItem.displayTheme.charAt(0).toLowerCase() +
          dataItem.displayTheme.slice(1),
      };
    });

    setLoading(false);

    setSubscriptionPlans(formattedData);
    setStudyPlans([]);
  }, []);

  const redirectCheckout = useCallback(() => {
    history.push("/finalizar-assinatura");
  }, [history]);

  const handleSubscriptionPlanClick = useCallback(
    (subscriptionPlan) => {
      setSelectedSubscriptionPlan(subscriptionPlan)
      setStorageSubscriptionPlan(subscriptionPlan)
      redirectCheckout()
    },
    [setSelectedSubscriptionPlan, setStorageSubscriptionPlan, redirectCheckout]
  )

  const handleStudyPlanClick = useCallback(
    (studyPlan) => {
      setSelectedStudyPlan(studyPlan);
      setStorageStudyPlan(studyPlan);

      redirectCheckout();
    },
    [setSelectedStudyPlan, setStorageStudyPlan, redirectCheckout]
  );

  const handleCheckoutComplete = useCallback(
    (idSubscription) => {
      setIdSubscrition(idSubscription);
      setStorageIdSubscription(idSubscription);

      history.push("/detalhes-assinatura");
    },
    [setStorageIdSubscription, history]
  );

  const handleCheckoutFinishStep = useCallback(
    (finishStep) => {
      setCheckoutFinishStep(finishStep)
    },
    []
  );

  const getInstallmentsAmount = useCallback(async (planId, couponCode) => {
    try {
      const response = await api.get('/payment/calculate-installments-amount', {
        params: {
          subscriptionPlanId: planId,
          discountCouponCode: couponCode || '',
        },
      })

      const formattedInstallmentsAmount = response.data.map(
        (installmentAmount) => {
          return {
            value: installmentAmount.installment,
            description: `${installmentAmount.installment}x de ${formatCurrency(
              installmentAmount.installmentAmount,
            )}`,
          }
        },
      )

      const installment = await formattedInstallmentsAmount[
        formattedInstallmentsAmount.length - 1
      ]?.description.split(' ')

      setInstallmentInUntil(installment)

      setInstallmentsAmount(formattedInstallmentsAmount)
    } catch (err) {
      history.push('/renovacao')
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getTastingValidate = useCallback(async (planId, couponCode) => {
    setLoading(true)

    try {
      const response = await api.get(`/subscriptionplan/${planId}/trial-period`, {
        params: {
          discountCouponCode: couponCode || '',
        },
      })

      const { data } = response

      setTastingPlan(data)
      setLoading(false)
    } catch (e) {
      setLoading(false)
    }
  }, [])

  const getCoupon = useCallback(async (planId, couponCode, toastCouponActive) => {
    setLoading(true)

    try {
      const response = await api.get('/student/subscriptionplan/coupon', {
        params: {
          SubscriptionPlanId: planId,
          DiscountCouponCode: couponCode || '',
        },
      })

      setCoupon(response.data)

      await getInstallmentsAmount(planId, couponCode)
      await getTastingValidate(planId, couponCode)

      if (toastCouponActive)
        toast.success('Cupom aplicado')

      setLoading(false)
    } catch (e) {
      setCouponInvalid(true)
      setCoupon('')
      getInstallmentsAmount(planId, '')
      getTastingValidate(planId, '')
      setLoading(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <DataContext.Provider
      value={{
        loading,

        getBanner,
        banner,

        getSubscriptionPlans,
        subscriptionPlans,
        handleSubscriptionPlanClick,
        selectedSubscriptionPlan,

        studyPlans,
        handleStudyPlanClick,
        selectedStudyPlan,

        redirectCheckout,

        handleCheckoutComplete,
        idSubscription,
        handleCheckoutFinishStep,
        checkoutFinishStep,

        getInstallmentsAmount,
        installmentsAmount,

        setInstallmentInUntil,
        installmentInUntil,
        setInstallmentInUntilCheck,
        installmentInUntilCheck,
        installmentSelected,
        setInstallmentSelected,

        getTastingValidate,
        setTastingPlan,
        tastingPlan,

        getCoupon,
        coupon,
        couponValue,
        setCoupon,
        setCouponValue,
        couponInvalid,
        setCouponInvalid,
        subscriptionPlanWithCouponAmount,
        setSubscriptionPlanWithCouponAmount,
        campaignName,
        setCampaignName,

        studentFirstName,
        setStudentFirstName,
        studentLastName,
        setStudentLastName,
        studentPhoneNumber,
        setStudentPhoneNumber,
        studentEmail,
        setStudentEmail,
      }}
    >
      {children}
    </DataContext.Provider>
  );
}

export { DataContext };

export default DataProvider;
