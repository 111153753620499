import React from 'react'
import { Row, Col } from 'react-flexbox-grid'
import { PlusCircle } from '@styled-icons/bootstrap'

import * as S from './styles'

function PlanFilter(props) {
  const { planBenefits, planFilter } = props

  return (
    <S.FilterWrapper>
      <Row start="xs" center="xs" className="benefits-row">
        {planBenefits?.filter((e) => e.filter === planFilter).map((item) => (
          <Col xs={24} sm={24} md={4} lg={2}
            className="benefits-wrapper"
            key={item.id}
          >
            <S.FilterWrapper>
              <S.Text
                fontFamily
                fontBold
                fontSmall
                colorPrimary
                textLeft
              >{item.title}</S.Text>

              <S.Text
                colorLight
                fontSmall
                textLeft
                dangerouslySetInnerHTML={{ __html: item.text }}
              >
              </S.Text>
            </S.FilterWrapper>

            {item.icon && (
              <S.Icon>
                <PlusCircle />
              </S.Icon>
            )}
          </Col>
        ))}
      </Row>
    </S.FilterWrapper>
  )
}

export default PlanFilter
