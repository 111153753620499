import React from 'react'

import benefits from './planBenefits'
import Filter from './filter'

import * as S from './styles'

function PlanBenefits() {
  return (
    <S.Container>
      <S.Wrapper>
        <S.Text
          fontBold
          colorLight
          fontLarge
          className="text"
        >
          Confira todos os benefícios que você irá liberar:
        </S.Text>

        <Filter
          planBenefits={benefits}
          planFilter={1}
        />
      </S.Wrapper>
    </S.Container>
  )
}

export default PlanBenefits
