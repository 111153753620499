/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react'
import StepWizard from 'react-step-wizard'
import pagarme from 'pagarme'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import schema from './schema'
import schemaCampaign from './schemaCampaign'
import Navigation from './components/Navigation'
import UserForm from './components/UserForm'
import AddressForm from './components/AddressForm'
import PaymentForm from './components/PaymentForm'
import CouponRedemption from '../CouponRedemption'
import toast from '../../shared/Toast'
import Header from '../../shared/Header'
import Container from '../../shared/Container'
import removeMask from '../../utils/remove-mask'
import useData from '../../hooks/useData'
import api from '../../services/api'
import services from '../Checkout/checkout.services'
import Loader from '../../shared/Loader'

function Checkout() {
  const [cartIdParams, setCartIdParams] = useState(false)
  const [cartId, setCartId] = useState(false)
  const [planIdCheck, setPlanIdCheck] = useState(false)
  const [useCouponRedemption, setUseCouponRedemption] = useState(false)
  const [error, setError] = useState(false)

  const {
    selectedSubscriptionPlan,
    handleCheckoutComplete,
    handleCheckoutFinishStep,
    handleSubscriptionPlanClick,
    getCoupon,
    coupon,
    setCouponValue,
    couponValue,
    campaignName,
    setCampaignName,
    setSubscriptionPlanWithCouponAmount,
    subscriptionPlanWithCouponAmount,
  } = useData()

  const urlSearchParams = new URLSearchParams(window.location.search)
  const cart = urlSearchParams.get('cart')
  const promotion = urlSearchParams.get('promo')
  const useCouponRedemptionUrlSearchParams = urlSearchParams.get('campanha')
  const useCampaignName = urlSearchParams.get('campaignName')

  useEffect(() => {
    if (['vivo-valoriza', 'super-revisao', 'conexia', 'socio-ceara', 'socio-bahia'].includes(useCouponRedemptionUrlSearchParams))
      setUseCouponRedemption(useCouponRedemptionUrlSearchParams)

    if (['vivo-valoriza', 'super-revisao', 'conexia', 'socio-ceara', 'socio-bahia', 'mega-help'].includes(useCampaignName))
      setCampaignName(useCampaignName)

    if (cart)
      setCartIdParams(cart)

    if (promotion)
      setCouponValue(promotion)
  }, [])

  const getSubscription = useCallback(
    async (value) => {
      if (!value) {
        setError(true)
      } else {
        setError(false)

        try {
          const subscriptionPlan = await services.getIdSubscriptionPlan(value)

          handleSubscriptionPlanClick(subscriptionPlan.data)
          setPlanIdCheck(true)
        } catch {
          setError(true)
        }
      }
    },
    [handleSubscriptionPlanClick]
  )

  useEffect(() => {
    getSubscription(cartIdParams)

    if (cartIdParams && promotion)
      getCoupon(cartIdParams, promotion)
  }, [cartIdParams])

  const formMethods = useForm({
    mode: 'onBlur',
    resolver: yupResolver(subscriptionPlanWithCouponAmount ? schemaCampaign : schema),
  })

  const { handleSubmit, watch, setValue } = formMethods

  const onStepChange = useCallback(
    async (stepChange) => {
      const cvc = watch('cvc')

      if (stepChange?.previousStep === 3 && cvc) setValue('cvc', '')

      if (coupon?.subscriptionPlanWithCouponAmount === 0)
        setSubscriptionPlanWithCouponAmount(true)
    }, [coupon, setSubscriptionPlanWithCouponAmount, setValue, watch])

  useEffect(() => {
    if (planIdCheck) {
      setCartId(true)
      onStepChange()
    }
  }, [onStepChange, planIdCheck])

  function validateCardInfo(name, expiry, number, cvc) {
    const card = {
      card_holder_name: name,
      card_expiration_date: expiry,
      card_number: number,
      card_cvv: cvc,
    }

    return pagarme.validate({ card: card })
  }

  function alertErrors(name, expiry, number, cvc) {
    let hasError = false

    if (!name) {
      toast.error('Nome do Cartão inválido.')
      hasError = true
    }

    if (!expiry) {
      toast.error('Validade do Cartão inválida.')
      hasError = true
    }

    if (!number) {
      toast.error('Número do Cartão inválido.')
      hasError = true
    }

    if (!cvc) {
      toast.error('Código de Segurança inválido.')
      hasError = true
    }

    handleCheckoutFinishStep(false)

    return hasError
  }

  async function onSubmit(values) {
    handleCheckoutFinishStep(true)

    const {
      firstName,
      lastName,
      cpf,
      email,
      mobile,
      password,

      cep,
      address,
      addressNumber,
      addressComplement,
      addressDistrict,
      addressCity,
      addressState,

      paymentMethodId,
      name,
      expiry,
      number,
      cvc,
      installments,
      discountCouponCode,
    } = values

    const data = {
      info: {
        firstName,
        lastName,
        cpf: cpf && removeMask(cpf),
        email,
        mobile: mobile && removeMask(mobile),
        password,
      },
      address: {
        cep: cep && removeMask(cep),
        address,
        addressNumber,
        addressComplement,
        addressDistrict,
        addressCity,
        addressState,
      },
      payment: {
        subscriptionPlanId: selectedSubscriptionPlan.id,
        paymentMethodId,
        discountCouponCode: campaignName ? couponValue : discountCouponCode,
        campaign: campaignName,
      },
    }

    if (paymentMethodId === 1) {
      const cardValidations = validateCardInfo(name, expiry, number, cvc)

      const {
        brand,
        card_holder_name,
        card_expiration_date,
        card_number,
        card_cvv,
      } = cardValidations.card

      if (
        alertErrors(
          card_holder_name,
          card_expiration_date,
          card_number,
          card_cvv
        )
      )

        return

      const response = await api.post('/payment/card-hash', {
        cardNumber: number,
        cardHolderName: name,
        cardExpirationDate: removeMask(expiry),
        cardCvv: cvc,
      })

      const paymentCardInfo = {
        creditCardMask: `${brand}-${number.slice(number.length - 4)}`,
        creditCardHash: response.data.cardHash,
        installments,
      }

      data.payment = { ...data.payment, ...paymentCardInfo }
    }

    try {
      const response = await api.post('/student/register', data)
      handleCheckoutComplete(response.data.subscriptionId)
      handleCheckoutFinishStep(false)
    } catch (e) {
      handleCheckoutFinishStep(false)
    }
  }

  return !cartId ? (
    <>
      <Loader minHeight={error && '50vh'} />

      {!!error && (
        <p style={{ textAlign: 'center' }}>
          Ops! Ocorreu um erro ao tentar iniciar sua assinatura.{' '}
          <a href="https://www.corujasabia.com.br/">Clique aqui</a> para tentar
          novamente.
        </p>
      )}
    </>
  ) : (
    !!useCouponRedemption ? (
      <FormProvider {...formMethods}>
        <GoogleReCaptchaProvider
          reCaptchaKey="6Lf-2XwbAAAAAL4tCl3x2Rmovp1mvn9uOXlz2alU"
          language="pt_BR"
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <CouponRedemption
              planId={cartIdParams}
              campaign={useCouponRedemption}
            />
          </form>
        </GoogleReCaptchaProvider>
      </FormProvider>
    ) : (
      <>
        <Header />

        <Container size="small">
          {!!cartId && (
            <FormProvider {...formMethods}>
              <GoogleReCaptchaProvider
                reCaptchaKey="6Lf-2XwbAAAAAL4tCl3x2Rmovp1mvn9uOXlz2alU"
                language="pt_BR"
              >
                <form onSubmit={handleSubmit(onSubmit)}>
                  <StepWizard nav={<Navigation />} onStepChange={onStepChange}>
                    <UserForm />

                    {!subscriptionPlanWithCouponAmount && (
                      <AddressForm />
                    )}

                    {!subscriptionPlanWithCouponAmount && (
                      <PaymentForm />
                    )}
                  </StepWizard>
                </form>
              </GoogleReCaptchaProvider>
            </FormProvider>
          )}
        </Container>
      </>
    )
  )
}

export default Checkout
