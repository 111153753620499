import styled, { css } from "styled-components";

const Container = styled.section`
  ${({ theme, size, noPadding }) => css`
    margin: 0 auto;
    padding: ${!noPadding && theme.spacings.large}
      calc(${theme.grid.gutter} / 2);
    max-width: ${theme.grid.container[size]};
  `}
`;

export { Container };
