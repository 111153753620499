import React, { useEffect, useState, useCallback } from 'react'
import { Row, Col } from 'react-flexbox-grid'
import { NavHashLink as Link } from 'react-router-hash-link';
import useData from '../../../hooks/useData'

import Input from '../../../shared/Input'

import imageLogo from '../../../assets/img/coruja-sabia-logo.svg'
import cearaLogo from './yourInformationAccess/images/team-flag.png'

/* icons */
import videoAulas from './yourInformationAccess/images/Icons/video-aulas.png'
import planoEstudos from './yourInformationAccess/images/Icons/plano-estudos.png'
import redacaoEnem from './yourInformationAccess/images/Icons/redacao-enem.png'
import simulados from './yourInformationAccess/images/Icons/simulados.png'
import listaExercicios from './yourInformationAccess/images/Icons/lista-exercicios.png'
import enem100 from './yourInformationAccess/images/Icons/enem-100.png'

import googlePlayImg from './yourInformationAccess/images/Plataforms/google-play-image.png'
import appStoreImg from './yourInformationAccess/images/Plataforms/app-store-img.png'

import * as S from './styles'

function SocioCeara(props) {
  const { planId, campaign } = props

  const [plan] = useState(planId)
  const [discountCouponCode, setDiscountCouponCode] = useState('')
  const [couponCheck, setCouponCheck] = useState(false)
  const [alertError, setAlertError] = useState(false)

  const {
    loading,
    getCoupon,
    coupon,
    couponInvalid,
    setCouponInvalid,
  } = useData()

  const checkButtons = useCallback((couponCode, type, emptyInvalid) => {
    setAlertError(false)

    if ((discountCouponCode && [undefined].includes(couponCode, type)) ||
      (discountCouponCode === '' && type === 'empty'))
      setAlertError(true)

    if (couponInvalid)
      setAlertError(true)

    if (emptyInvalid)
      setAlertError(false)
  }, [coupon, couponInvalid, discountCouponCode])

  useEffect(() => {
    setCouponCheck(false)

    if (discountCouponCode)
      checkButtons(discountCouponCode, '')

    if (coupon) {
      setCouponInvalid(false)
      setAlertError(false)
      setCouponCheck(true)
    }
  }, [coupon])

  useEffect(() => {
    if (couponInvalid)
      checkButtons(discountCouponCode, 'invalid')

    if (!couponInvalid)
      checkButtons('', '', 'emptyInvalid')
  }, [couponInvalid])

  useEffect(() => {
    if (!loading && couponCheck)
      window.location.href = `/finalizar-assinatura?cart=${plan}&promo=${discountCouponCode}&campaignName=${campaign}`
  }, [loading, couponCheck])

  async function handleCouponCheck(value) {
    setDiscountCouponCode(value)

    if (value) {
      await getCoupon(plan, discountCouponCode, 'toastCouponActive')
    } else {
      checkButtons(value, 'empty')
      setCouponInvalid(false)
    }
  }

  function handleClickButtonPlattaform(plataform) {
    if(plataform ==='android') {
      window.open('https://play.google.com/store/apps/details?id=com.coruja_sabia&hl=pt', '_blank')
    }
    else {
      window.open('https://apps.apple.com/br/app/coruja-s%C3%A1bia/id1550943964', '_blank')
    }


  }

  return (
    <S.Container>
      <Row start="xs" center="xs" className="row-background">
        <Col xs={12} sm={12} md={12} lg={6}>
          <S.Wrapper  id='resgatar'>
            <S.Image src={imageLogo} title="Logo Coruja Sábia" />

            <S.TextMain >Coruja Sábia e Sócio Torcedor Ceará </S.TextMain>
            <S.TextSubTextMain fontFamily>Uma parceria campeã</S.TextSubTextMain>
            <S.Image src={cearaLogo} title="Logo Time Ceará" />
            <S.Text fontSmall >Insira o cupom para resgatar o seu benefício e<br/>
            fazer parte do nosso <b>time de aprovados!</b></S.Text>

            <S.InputWrapper className={alertError ? 'alert-error' : ''}>
              <Input
                name="discountCouponCode"
                placeholder="Informe o código do seu cupom"
                value={discountCouponCode}
                onChange={(e) => setDiscountCouponCode(e.target.value)}
                layout
              />
            </S.InputWrapper>

            <S.Button onClick={() => handleCouponCheck(discountCouponCode)}

            >
              RESGATAR BENEFÍCIO
            </S.Button>
            <S.Text fontSmall>Comece a estudar grátis para o ENEM</S.Text>
          </S.Wrapper>
        </Col>
      </Row>

      <S.Wrapper withMargin>
        <Row start="xs" center="xs" className="information-access" >
          <S.Text
            fontBold
            fontSmall
            fontFamily
            colorTextDefault
            className="text"
          >
            Ao quê você vai ter acesso:
          </S.Text>
          <S.Text
            fontBold
            fontXlarge
            fontFamily
            colorTextDefault
            boldHighlight
            className="text"
          >
            Super Curso <b >ENEM</b> 2022
          </S.Text>
          </Row>
          <Row style={{justifyContent:'center'}} >
          <Row gutter={[24,48]} justify='center' align='middle' >
            <Col xs={12}  md={6} lg={4}>
              <S.CardImage>
                <S.Image src={videoAulas} title="Mais de 2.000 aulas"/>

                </S.CardImage>
                <S.CardTitle>Mais de 2.000 aulas</S.CardTitle>
              </Col>

              <Col xs={12}  md={6} lg={4}>
                <S.CardImage>
                <S.Image src={planoEstudos} title="Plano de Estudos"
                />
                </S.CardImage>

                <S.CardTitle>Plano de Estudos</S.CardTitle>
              </Col>

              <Col xs={12}  md={6} lg={4}>
                <S.CardImage>
                  <S.Image src={redacaoEnem} title="Aulas de Redação ENEM" />
                </S.CardImage>

                <S.CardTitle>Aulas de Redação <br/> ENEM</S.CardTitle>
              </Col>

              <Col xs={12}  md={6} lg={4}>
                <S.CardImage>
                  <S.Image src={simulados} title="Simulados" />
                </S.CardImage>

                <S.CardTitle>Simulados</S.CardTitle>
              </Col>

              <Col xs={12}  md={6} lg={4}>
                <S.CardImage>
                <S.Image src={listaExercicios} title="Lista de Exercícios" />
                </S.CardImage>

                <S.CardTitle>Lista de Exercícios</S.CardTitle>

              </Col>
              <Col xs={12}  md={6} lg={4}>
                  <S.CardImage>
                    <S.Image src={enem100} title="As 100+ do ENEM" />
                  </S.CardImage>

                  <S.CardTitle>As 100+ do  ENEM</S.CardTitle>
                </Col>
            </Row>

        </Row>
      </S.Wrapper>
      <S.Wrapper  >
        <Row start="xs" center="xs" className="want-more" style={{backgroundColor:'#4454CD'}}>
            <Col xs={12} md={12}  lg={12}>
            <Link  style={{textDecoration:'none'}}  to={{hash: '#resgatar'}} >
              <S.Button >
                 RESGATAR BENEFÍCIO
              </S.Button>
              </Link>
              <S.Text fontSmall>Faça o download do aplicativo:</S.Text>
              <S.AppsBox >
                  <S.Image cursorON src={appStoreImg} onClick={() => handleClickButtonPlattaform('ios')} title="Plataforma AppStore" />
                  <S.Image cursorON src={googlePlayImg} onClick={() => handleClickButtonPlattaform('android')} title="Plataforma GooglePlay" />
              </S.AppsBox>


            </Col>
          </Row>
          <Row>

          <S.Footer >
                <S.Text font2xSmall>© Coruja Sábia {new Date().getFullYear()}. Todos os direitos reservados.</S.Text>
          </S.Footer>
          </Row>

      </S.Wrapper>

    </S.Container>
  )
}

export default SocioCeara
