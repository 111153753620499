import styled, { css } from "styled-components";
import { darken } from "polished";

const StepsButtonWrapper = styled.div`
  ${({ theme }) => css`
    margin-top: ${theme.spacings.small};
  `}

  .loading-active {
    max-width: 20%;
    flex-basis: 20%;

    img {
      margin-right: 10px;
    }
  }
`;

const StepsButtonBase = styled.button`
  ${({ theme }) => css`
    width: 100%;
    padding: ${theme.spacings.xxsmall};
    border-radius: ${theme.border.radius};
    font-weight: ${theme.font.bold};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  `}
`;

const StepsButtonPrevious = styled(StepsButtonBase)`
  ${({ theme }) => css`
    color: ${theme.colors.dark};

    :hover {
      color: ${darken(0.4, `${theme.colors.dark}`)};
    }

    :focus {
      color: ${darken(0.4, `${theme.colors.dark}`)};
    }

    :disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }
  `}
`;

const StepsButtonNext = styled(StepsButtonBase)`
  ${({ theme }) => css`
    background-color: ${theme.colors.primary};
    color: ${theme.colors.white};

    :hover {
      background-color: ${darken(0.1, `${theme.colors.primary}`)};
    }

    :focus {
      background-color: ${darken(0.1, `${theme.colors.primary}`)};
    }
  `}
`;

export { StepsButtonWrapper, StepsButtonPrevious, StepsButtonNext };
