import React from "react";
import DataProvider from "./data";
import ThemeProvider from "./theme";

function Providers(props) {
  const { children } = props;

  return (
    <ThemeProvider>
      <DataProvider>{children}</DataProvider>
    </ThemeProvider>
  );
}

export default Providers;
