import axios from "axios";
import toast from "../shared/Toast";

const baseURL = "https://api.corujasabia.com.br/api/v1";

const api = axios.create({
  baseURL,
});

api.defaults.headers.common["X-Application-Key"] = "webkey123";
api.defaults.headers.common["Accept-User-Agent"] =
  navigator.userAgent + ".c3.15.18.21.10.1";

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const { data, status } = error.response;

    switch (status) {
      case 400:
        toast.error(data.message);
        break;
      default:
        toast.error("Erro inesperado.");
        break;
    }

    return Promise.reject(error);
  }
);

export default api;
