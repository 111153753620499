import * as yup from "yup";
import { cpf as cpfValidator } from "cpf-cnpj-validator";

import api from "../../services/api";

import removeMask from "../../utils/remove-mask";

let preventCpfValidation = {};
let preventEmailValidation = {};

async function cpfExists(cpf) {
  if (cpf === preventCpfValidation.value) return preventCpfValidation.valid;

  if (cpf) {
    const response = await api.get("/student/check-cpf", {
      params: {
        cpf: removeMask(cpf),
      },
    });

    const uniqueCpf = !response.data.cpfExists;

    preventCpfValidation = {
      value: cpf,
      valid: uniqueCpf,
    };

    return uniqueCpf;
  }
}

async function emailExists(email) {
  if (email === preventEmailValidation.value)
    return preventEmailValidation.valid;

  if (email) {
    const response = await api.get("/student/check-email", {
      params: {
        email,
      },
    });

    const uniqueEmail = !response.data.emailExists;

    preventEmailValidation = {
      value: email,
      valid: uniqueEmail,
    };

    return uniqueEmail;
  }
}

function installmentsTransform(value, originalValue) {
  if (originalValue === "") return 0;
  return value;
}

const schema = yup.object().shape({
  firstName: yup.string().required("Nome obrigatório."),
  lastName: yup.string().required("Sobrenome obrigatório."),
  cpf: yup
    .string()
    .required("CPF obrigatório.")
    .test("cpfValidator", "CPF inválido.", cpfValidator.isValid)
    .test("cpfExists", "CPF já cadastrado.", cpfExists),
  email: yup
    .string()
    .required("E-mail obrigatório.")
    .email("E-mail inválido.")
    .test("emailExists", "E-mail já cadastrado.", emailExists),
  mobile: yup
    .string()
    .required("Telefone/Celular obrigatório.")
    .min(14, "Telefone/Celular deve ter no mínimo 8 números."),
  password: yup
    .string()
    .required("Senha obrigatória.")
    .min(6, "Senha deve possuir no mínimo 6 caracteres."),

  cep: yup.string().required("CEP obrigatório."),
  address: yup.string().required("Endereço obrigatório."),
  addressNumber: yup.string().required("Número obrigatório."),
  addressComplement: yup.string(),
  addressDistrict: yup.string().required("Bairro obrigatório."),
  addressCity: yup.string().required("Cidade obrigatória."),
  addressState: yup.string().required("Estado obrigatório."),

  paymentMethodId: yup.number(),
  name: yup.string().when("paymentMethodId", {
    is: 1,
    then: yup.string().required("Nome do Cartão obrigatório."),
  }),
  holderCpf: yup.string().when("paymentMethodId", {
    is: 1,
    then: yup
      .string()
      .required("CPF obrigatório.")
      .test("cpfValidator", "CPF inválido.", cpfValidator.isValid),
  }),
  number: yup.string().when("paymentMethodId", {
    is: 1,
    then: yup.string().required("Número do Cartão obrigatório."),
  }),
  expiry: yup.string().when("paymentMethodId", {
    is: 1,
    then: yup.string().required("Validade do Cartão obrigatória."),
  }),
  cvc: yup.string().when("paymentMethodId", {
    is: 1,
    then: yup.string().required("Código de Segurança obrigatório."),
  }),
  installments: yup
    .number()
    .transform(installmentsTransform)
    .when("paymentMethodId", {
      is: 1,
      then: yup
        .number()
        .required("Número de Parcelas obrigatório.")
        .min(1, "Número de Parcelas obrigatório."),
    }),
  termsConditions: yup
    .boolean()
    .oneOf([true], "Termos e Condições obrigatório."),
});

export default schema;
