import styled, { css } from "styled-components";

const NavigationWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const NavigationDot = styled.span`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacings.small};
    font-size: ${theme.font.sizes.medium};
    opacity: 0.4;

    &.active {
      color: ${theme.colors.primary};
      text-shadow: 0 0 8px;
      opacity: 1;
    }
  `}
`;

export { NavigationWrapper, NavigationDot };
