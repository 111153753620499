import { createGlobalStyle, css } from "styled-components";
import "react-toastify/dist/ReactToastify.css";
import "react-credit-cards/es/styles-compiled.css";

import "./font-face.css";

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
  }

  ${({ theme }) => css`
    html {
      font-family: ${theme.font.family}, sans-serif;
      font-size: 62.5%;
      scroll-behavior: smooth;
    }

    body {
      overflow-x: hidden;
      background-color: ${theme.colors.background};
      font-size: ${theme.font.sizes.small};
      color: ${theme.colors.text};
    }

    input,
    select,
    button {
      font-family: ${theme.font.family}, sans-serif;
    }

    a {
      color: ${theme.colors.primary};
    }
  `}
`;

export default GlobalStyle;
