import styled, { css } from 'styled-components'

const Container = styled.div`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacings.medium};
    width: 100%;
  `}
`

const Description = styled.div``

const Text = styled.p`
  ${({ theme }) => css`
    margin-bottom: ${(props) => props.margin ? '15px' : ''};
    min-height: ${theme.height.small};
    color: ${theme.colors.textLight};
    font-size: ${(props) => props.fontSmall ? `${theme.font.sizes.small}` : `${theme.font.sizes.large}`};
    font-weight: ${(props) => props.fontBold ? `${theme.font.bold}` : ''};
    ${(props) => props.fontFamily ? `font-family: ${theme.font.familySecondary}` : ''};
  `}
`

const Video = styled.div`
  ${({ theme }) => css`
    position:relative;
    margin-top: ${theme.spacings.small};
    padding: 56.25% 0 0 0;
    width: ${theme.width.large};

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: ${theme.width.large};
      height: ${theme.width.large};
    }
  `}
`

export {
  Container,
  Description,
  Text,
  Video,
}
