/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, lazy, Suspense } from "react";
import { useFormContext } from "react-hook-form";
import { Row, Col } from "react-flexbox-grid";

import StepsButton from "../StepsButton";
import SubscriptionTastingMessage from '../SubscriptionTastingMessage'

import Heading from "../../../../shared/Heading";
import Input from "../../../../shared/Input";
import InputMask from "../../../../shared/InputMask";
import toast from "../../../../shared/Toast";
import useData from '../../../../hooks/useData'
import Coupon from "../PaymentForm/Coupon";
import DataLayer from '../DataLayer'

import * as S from './styles'

const PasswordStrenght = lazy(() => import("../PasswordStrenght"));

function UserForm(props) {
  const { watch, trigger, errors } = useFormContext();

  const { currentStep, previousStep, nextStep } = props;

  const cpfExistsError = !!(
    errors["cpf"] && errors["cpf"].type === "cpfExists"
  );

  const emailExistsError = !!(
    errors["email"] && errors["email"].type === "emailExists"
  );

  const password = watch("password");

  const {
    selectedSubscriptionPlan,
    tastingPlan,
    subscriptionPlanWithCouponAmount,
    campaignName,
    studentFirstName,
    setStudentFirstName,
    studentLastName,
    setStudentLastName,
    studentPhoneNumber,
    setStudentPhoneNumber,
    studentEmail,
    setStudentEmail,
  } = useData()

  useEffect(() => {
    if (cpfExistsError || emailExistsError)
      toast.warning(
        "Já existe um cadastro com o CPF ou E-mail informado. Clique aqui para fazer login ou redefinir sua senha.",
        {
          position: "top-left",
          onClick: redirectStudentPortal,
        }
      );
  }, [cpfExistsError, emailExistsError])

  function redirectStudentPortal() {
    window.location.href =
      "https://aluno.corujasabia.com.br/login";
  }

  function beforeMaskedValueChange(state) {
    let { value } = state;

    const newValue = value.replace(/\D/g, "");

    if (newValue.length === 11)
      value = newValue.replace(/^(\d{2})(\d{5})(\d{4})$/, "($1) $2-$3");

    setStudentPhoneNumber(value)

    return {
      ...state,
      value,
    };
  }

  async function validateInputs() {
    return trigger([
      "firstName",
      "lastName",
      "cpf",
      "email",
      "mobile",
      "password",
    ])
  }

  async function validateInputsCampaign() {
    return trigger([
      "firstName",
      "lastName",
      "email",
      "password",
      "termsConditions",
    ])
  }

  function ErrorValidate() {
    return (
      <a
        href="/"
        target="_blank"
        rel="noopener noreferrer"
        onClick={redirectStudentPortal}
      >
        Clique aqui para fazer login
      </a>
    )
  }

  return (
    <>
      <Heading lineBottom>Usuário</Heading>

      {!!subscriptionPlanWithCouponAmount && (
        <Row
          center="xs"
          style={{ marginTop: 10, marginBottom: 15 }}
        >
          <Col xs={24} md={24}>
            <b>Você está prestes a desbloquear o seu benefício gratuito</b><br />

            Para continuar, informe os seus dados para que possamos criar o seu cadastro.
          </Col>
        </Row>
      )}

      <Row>
        <Col xs={12} md={6}>
          <Input
            name="firstName"
            placeholder="Nome"
            onChange={(e) => setStudentFirstName(e.target.value)}
          />
        </Col>

        <Col xs={12} md={6}>
          <Input
            name="lastName"
            placeholder="Sobrenome"
            onChange={(e) => setStudentLastName(e.target.value)}
          />
        </Col>
      </Row>

      {!subscriptionPlanWithCouponAmount && (
        <Row>
          <Col xs={12} md={6}>
            <S.ErrorWrapper className={!!cpfExistsError && "exists-error"}>
              <InputMask
                inputMode="numeric"
                name="cpf"
                placeholder="CPF"
                mask="999.999.999-99"
              />

              {!!cpfExistsError && (
                <ErrorValidate />
              )}
            </S.ErrorWrapper>
          </Col>

          <Col xs={12} md={6}>
            <InputMask
              type="tel"
              name="mobile"
              placeholder="Telefone/Celular"
              mask="(99) 9999-99999"
              beforeMaskedValueChange={beforeMaskedValueChange}
            />
          </Col>
        </Row>
      )}

      <Row>
        <Col xs={12} md={6}>
          <S.ErrorWrapper className={!!emailExistsError && "exists-error"}>
            <Input
              type="email"
              name="email"
              placeholder="E-mail"
              onChange={(e) => setStudentEmail(e.target.value)}
            />

            {!!emailExistsError && (
              <ErrorValidate />
            )}
          </S.ErrorWrapper>
        </Col>

        <Col xs={12} md={6}>
          <Input
            type="password"
            name="password"
            placeholder="Senha"
            maxLength={16}
          />

          <Suspense fallback={<span />}>
            <PasswordStrenght password={password} />
          </Suspense>
        </Col>
      </Row>

      {!!campaignName && !subscriptionPlanWithCouponAmount && (
        <Coupon
          subscriptionPlanId={selectedSubscriptionPlan.id}
          currentStep={currentStep}
        />
      )}

      {!!campaignName && !!tastingPlan?.subscriptionPlanTrial && (
        <SubscriptionTastingMessage
          initialDate={tastingPlan.trialPeriodStart}
          endDate={tastingPlan.trialPeriodEnd}
          billingDate={tastingPlan.trialPeriodSubscriptionChargeDate}
          paymentMethod={1}
          numberDays={tastingPlan.numberDays}
          subscriptionPlanWithCouponAmount={subscriptionPlanWithCouponAmount}
        />
      )}

      {!!subscriptionPlanWithCouponAmount && (
        <Row end="xs" style={{ marginTop: 10 }}>
          <Col xs={12}>
            <Input type="checkbox" name="termsConditions">
              <span style={{ fontSize: 14 }}>
                Li e concordo com os{" "}
                <a href="https://corujasabia.com.br/termos-de-uso" target="_blank" rel="noopener noreferrer">
                  termos e condições
                </a>
                .
              </span>
            </Input>
          </Col>
        </Row>
      )}

      <StepsButton
        validateInputs={!!subscriptionPlanWithCouponAmount ? validateInputsCampaign : validateInputs}
        currentStep={currentStep}
        previousStep={previousStep}
        nextStep={nextStep}
      />

      {currentStep === 1 && (
        <DataLayer
          campaign={campaignName}
          subscriptionPlanInfo={{
            'subscriptionPlanId': selectedSubscriptionPlan.id,
            'subscriptionPlanName': selectedSubscriptionPlan.name,
            'subscriptionPlanValue': selectedSubscriptionPlan.amountWithDescount ?
              selectedSubscriptionPlan.amountWithDescount :
                selectedSubscriptionPlan.amount
          }}
          studentInfo={{
            'studentFirstName': studentFirstName,
            'studentLastName': studentLastName,
            'studentPhoneNumber': studentPhoneNumber,
            'studentEmail': studentEmail
          }}
          paymentInfo={{}}
        />
      )}
    </>
  );
}

export default UserForm;
