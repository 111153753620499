import styled, { css } from 'styled-components'
import media from 'styled-media-query'

const Container = styled.div`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacings.medium};
    width: 100%;

    .text {
      width: ${theme.width.large};
      display: block;
    }
`}
`

const Col = styled.div`
  ${({ theme }) => css`
    width: ${theme.width.xhalf};
    display: inline-block;
  `}
`

const Wrapper = styled.div`
  ${({ theme }) => css`
    display: ${(props) => props.flex ? 'flex' : 'block'};

    .benefits-wrapper {
      margin-top: ${theme.spacings.small};
      display: flex;
      justify-content: center;
      align-items: flex-start;

      ${media.lessThan('medium')`
        width: ${theme.width.large};
        display: block;

        p {
          text-align: center;
        }
      `}
    }

    ${media.lessThan('medium')`
      width: ${theme.width.large};
    `}
  `}
`

const FilterWrapper = styled.div`
  ${({ theme }) => css`
    ${media.between('900px', '1250px')`
      .benefits-row {
        display: block;
      }

      .benefits-wrapper {
        margin: ${theme.spacings.small} ${theme.spacings.auto};
        max-width: 33.33333333%;
        flex-basis: 33.33333333%;
        display: block;
      }
    `}
  `}
`

const TextMain = styled.h1`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacings.medium};
    color: ${theme.colors.white};
    font-size: ${theme.font.sizes.xxlarge};
  `}
`

const Text = styled.p`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacings.xsmall};
    color: ${(props) => props.colorPrimary ? `${theme.colors.primary}` :
      (props.colorLight ? `${theme.colors.textLight}` : `${theme.colors.dark}`)};
    font-size: ${(props) => props.fontLarge ? `${theme.font.sizes.large}` :
      (props.fontSmall ? `${theme.font.sizes.small}` :
        (props.fontXlarge ? `${theme.font.sizes.xlarge}` : `${theme.font.sizes.medium}`))};
    font-weight: ${(props) => props.fontBold ? `${theme.font.bold}` : ''};
    ${(props) => props.fontFamily ? `font-family: ${theme.font.familySecondary}` : ''};
    text-align: ${(props) => props.textLeft ? 'left' : 'center'};
  `}
`

const Icon = styled.div`
  ${({ theme }) => css`
    margin-top: ${theme.spacings.x1large};
    width: 100%;
    color: ${theme.colors.secondary};
    display: block;

    svg {
      width: ${theme.font.sizes.medium};
    }

    ${media.lessThan('1250px')`
      margin-top: ${theme.spacings.empty};
    `}

    ${media.greaterThan('1700px')`
      margin-top: ${theme.spacings.large};
    `}
  `}
`

export {
  Container,
  Col,
  Wrapper,
  FilterWrapper,
  TextMain,
  Text,
  Icon,
}
