import styled, { css } from "styled-components";

const ErrorMessageWrapper = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.font.sizes.xxsmall};
    color: #ff4d4f;
  `}
`;

export { ErrorMessageWrapper };
