import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import * as S from "./styles";

import corujaSabiaWhite from "../../assets/img/coruja-sabia-white.svg";

function Header() {
  function loadScript() {
    let script = document.createElement('script')

    script.type = 'text/javascript'

    if (script) {
      script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!=='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-TW8T8SX')`
    }

    document.getElementsByTagName('body')[0].appendChild(script)
  }

  useEffect(() => {
    loadScript()
  }, [])

  return (
    <S.HeaderWrapper>
      <Link to="/">
        <S.HeaderImage src={corujaSabiaWhite} alt="Coruja Sábia" />
      </Link>
    </S.HeaderWrapper>
  );
}

export default Header;
