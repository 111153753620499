import React from "react";
import {
  CheckCircle,
  ExclamationCircle,
  XCircle,
} from "@styled-icons/bootstrap";

import * as S from "../styles";

function StatusInfo(props) {
  const { id, payment } = props;

  function handleIcon() {
    if (payment.status === 1 || payment.status === 2)
      return <ExclamationCircle width="3em" color="orange" />;

    if (payment.status === 3 || payment.status === 4)
      return <CheckCircle width="3em" color="green" />;

    if (payment.status === 5 || payment.status === 6)
      return <XCircle width="3em" color="red" />;
  }

  function handleMessage() {
    if (payment.status === 1 || payment.status === 2)
      if (payment.paymentMethodId === 1)
        return "Sua assinatura está sendo processada…";
      else return "Aguardando pagamento do boleto…";

    if (payment.status === 4) return "Sua assinatura foi confirmada!";

    if (payment.status === 5 || payment.status === 6)
      if (payment.paymentMethodId === 1)
        return "O pagamento em seu cartão de crédito foi recusado...";
      else return "O pagamento do boleto não foi confirmado...";
  }

  return (
    <S.DetailsInfoWrapper>
      {handleIcon()}

      <S.DetailsStatus>{payment.statusDescription}</S.DetailsStatus>
      {handleMessage()}

      {payment.status === 4 && (
        <S.DetailsSubscriptionId>
          O número de sua assinatura é: {id}
        </S.DetailsSubscriptionId>
      )}
    </S.DetailsInfoWrapper>
  );
}

export default StatusInfo;
