import styled, { css } from "styled-components";
import media from "styled-media-query";

const CardWrapper = styled.div`
  ${({ theme }) => css`
    margin-right: ${theme.spacings.small};
    margin-bottom: ${theme.spacings.small};

    ${media.greaterThan("medium")`
      margin-bottom: 0;
    `}

    ${media.greaterThan("medium")`
      float: left;
    `}
  `}
`;

export { CardWrapper };
